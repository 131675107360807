<template>
  <div
    ref="tableWrapper"
    :tabindex="table.info.id"
    class="tbi-none"
    :class="vcentred ? 'vcentred' : ''"
  >
    <v-row class="header-fixed__table">
      <v-col v-if="showTableTitle">
        <h3 class="mb-2">
          {{ table.info.title }}
        </h3>
      </v-col>
      <v-col
        class="text-right d-flex justify-end"
      >
        <template
          v-if="showIfNotPrint"
        >
          <row-filter
            v-if="showFilter"
            :table="table"
            @changeFilterCheckbox="recalcFixHeader"
          />
          <v-btn
            icon
            dark
            :color="'blue-grey lighten-3'"
            @click="showFilter= !showFilter"
          >
            <v-icon large>
              mdi-filter-outline
            </v-icon>
          </v-btn>
          <v-icon class="beta-labl">
            mdi-beta
          </v-icon>
        </template>
        <template
          v-if="!presentation"
        >
          <div v-if="arrChangesTable.length">
            <v-tooltip
              v-if="canEdit"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  :dark="curStepChanges > 0"
                  :disabled="curStepChanges === 0"
                  :color="'blue-grey lighten-3'"
                  icon
                  text
                  v-on="on"
                  @click="changeBack"
                >
                  <v-icon
                    large
                    :style="curStepChanges === 0?'color: rgba(0, 0, 0, 0.1) !important;':''"
                  >
                    mdi-undo
                  </v-icon>
                </v-btn>
              </template>
              <span>Назад</span>
            </v-tooltip>
            <v-tooltip
              v-if="canEdit"
              bottom
            >
              <template #activator="{ on, attrs }">
                <!--              curStepChanges-->
                <v-btn
                  v-bind="attrs"
                  :dark="false"
                  :disabled="arrChangesTable.length === curStepChanges"
                  :color="'blue-grey lighten-3'"
                  icon
                  text
                  v-on="on"
                  @click="changeForward"
                >
                  <v-icon
                    large
                    :style="arrChangesTable.length === curStepChanges ? 'color: rgba(0, 0, 0, 0.1) !important;' : ''"
                  >
                    mdi-redo
                  </v-icon>
                </v-btn>
              </template>
              <span>Вперед</span>
            </v-tooltip>
          </div>

          <v-tooltip
            v-if="canEdit"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                :dark="tableChange"
                :disabled="!tableChange"
                :color="'blue-grey lighten-3'"
                icon
                text
                v-on="on"
                @click="reverseChanges()"
              >
                <v-icon
                  :style="!tableChange?'color: rgba(0, 0, 0, 0.1) !important;':''"
                  large
                >
                  mdi-history
                </v-icon>
              </v-btn>
            </template>
            <span>Отменить изменения</span>
          </v-tooltip>
          <v-tooltip
            v-if="!!table.info.hassnapshots && $route.path !== '/snapshotinfo'"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                dark
                icon
                :color="'blue-grey lighten-3'"
                v-on="on"
                @click="showSnapHistory(table.info.id)"
              >
                <v-icon large>
                  mdi-clipboard-text-clock-outline
                </v-icon>
              </v-btn>
            </template>
            <span>История изменений</span>
          </v-tooltip>
          <v-tooltip
            v-if="chpattern"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                dark
                icon
                :color="'blue-grey lighten-3'"
                v-on="on"
                @click="$emit('showChart')"
              >
                <v-icon large>
                  mdi-chart-bar
                </v-icon>
              </v-btn>
            </template>
            <span>Показать график</span>
          </v-tooltip>
          <v-tooltip
            v-if="rightadvanced"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                dark
                icon
                :color="'blue-grey lighten-3'"
                v-on="on"
                @click="openAddChartModal(table.info.id)"
              >
                <v-icon large>
                  mdi-shape-rectangle-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Добавить график</span>
          </v-tooltip>
          <v-tooltip
            v-if="canEdit"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                dark
                icon
                color="blue-grey lighten-3"
                v-on="on"
                @click="recalcOuterData(table)"
              >
                <v-icon large>
                  mdi-database-sync-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Обновить внешние данные</span>
          </v-tooltip>
          <v-tooltip
            v-if="!!table.info.flgdynamicrows && canEdit && !table.info.parenttabledataid && (rightadmin || rightadvanced)"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                dark
                icon
                :color="'blue-grey lighten-3'"
                :loading="gettingObjects"
                v-on="on"
                @click="importCSV = true"
              >
                <v-icon large>
                  mdi-database-import-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Импорт данных</span>
          </v-tooltip>
          <v-tooltip
            v-if="!!table.info.flgdynamicrows && canEdit && !table.info.parenttabledataid && (rightadmin || rightadvanced)"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                dark
                icon
                :color="'blue-grey lighten-3'"
                :loading="gettingObjects"
                v-on="on"
                @click="addRow()"
              >
                <v-icon large>
                  mdi-plus-box
                </v-icon>
              </v-btn>
            </template>
            <span>Добавить строку</span>
          </v-tooltip>
          <v-tooltip
            v-if="canEdit && table.info.flgdynamictable && table.info.candelete"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                dark
                icon
                color="red accent-2"
                v-on="on"
                @click="delDynTable(table)"
              >
                <v-icon large>
                  mdi-trash-can
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить таблицу</span>
          </v-tooltip>
          <v-tooltip
            v-if="canEdit"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                :dark="tableChange"
                icon
                text
                color="red accent-1"
                :disabled="!tableChange"
                v-on="on"
                @click="justSave(table)"
              >
                <v-icon
                  :style="!tableChange?'color: rgba(0, 0, 0, 0.1) !important;':''"
                  large
                >
                  mdi-content-save
                </v-icon>
              </v-btn>
            </template>
            <span>Сохранить изменения без перерасчета</span>
          </v-tooltip>
          <v-tooltip
            v-if="canEdit"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                :dark="tableChange"
                icon
                text
                color="red accent-2"
                :disabled="!tableChange"
                v-on="on"
                @click="saveAndRecalc(table)"
              >
                <v-icon
                  :style="!tableChange?'color: rgba(0, 0, 0, 0.1) !important;':''"
                  large
                >
                  mdi-content-save
                </v-icon>
              </v-btn>
            </template>
            <span>Сохранить изменения</span>
          </v-tooltip>
        </template>
      </v-col>
    </v-row>
    <div
      v-if="table.info.id && (table.data || presentation)"
    >
      <div ref="wrapperWidth">
        <table
          :id="'table-' + table.info.id"
          :ref="'table-' + table.info.id"
          v-click-outside="onClickOutside"
          class="cell-border"
          :style="colsInPx.state ? 'width:' + colsInPx.width + 'px' : 'width:100%'"
          @mouseleave="$emit('showhint', null)"
        >
          <draggable
            v-model="rows"
            :disabled="selectedCell"
            draggable=".can-reorder"
            tag="tbody"
          >
            <template v-if="!headersHaveGroup">
              <tr
                slot="header"
                ref="fixedHeaderTr"
              >
                <template v-if="rowGroups && !print">
                  <td
                    v-for="n in maxLevel"
                    :key="n"
                    style="width: 20px;"
                    class="collapse-col"
                  >
                    <div
                      class="header-cell"
                    >
                      <v-btn
                        outlined
                        x-small
                        style="padding:0px; min-width:18px;"
                        @click="showLevel(n)"
                      >
                        {{ n }}
                      </v-btn>
                    </div>
                  </td>
                </template>
                <td
                  v-for="(col, colindex) in headersPerform"
                  :id="'cell-' + table.info.id + '-' + colindex + '--1'"
                  :key="col.value+''"
                  :style="compileHeight(col.rowspan, headerStyles.height) + 'width:' + col.width + ';'"
                  :class="[col.value === 'collapse' ? 'collapse-col' : '',chosenClass(colindex, -1),presentation ? 'ts-' + table.info.header_presentation_stylesid.replaceAll(',','-') : 'ts-' + table.info.header_stylesid.replaceAll(',','-')]"
                  style="border: 1px solid black; position: relative"
                  @click="setSelected(colindex, -1, $event)"
                  @dblclick="sortData(col)"
                >
                  <div
                    class="header-cell"
                    :style="compileHeight(col.rowspan, headerStyles.height)"
                  >
                    {{ col.text }}
                    <v-icon
                      v-if="colSortDir[col.value]"
                      x-small
                    >
                      {{ colSortDir[col.value] === 'desc' ? 'mdi-arrow-down-box' : 'mdi-arrow-up-box' }}
                    </v-icon>
                  </div>
                </td>
                <td
                  v-if="haveDelRow && canEdit && !presentation"
                  style="width: 29px;"
                  :class="presentation ? 'ts-' + table.info.header_presentation_stylesid.replaceAll(',','-') : 'ts-' + table.info.header_stylesid.replaceAll(',','-')"
                >
                  <div
                    class="header-cell"
                    :style="compileHeight(0, headerStyles.height)"
                  />
                </td>
              </tr>
            </template>
            <template v-else>
              <tr
                slot="header"
                ref="fixedHeaderTr2"
              >
                <td
                  v-if="rowGroups && !print"
                  style="width: 20px;"
                  rowspan="2"
                  :class="presentation ? 'ts-' + table.info.header_presentation_stylesid.replaceAll(',','-') : 'ts-' + table.info.header_stylesid.replaceAll(',','-')"
                  :style="compileHeight(2, headerStyles.height)"
                >
                  <div
                    class="header-cell"
                  />
                </td>
                <td
                  v-for="(col, colindex) in headersGroups.cols"
                  :id="'cell-' + table.info.id + '-' + col.colIndex + '--2'"
                  :key="'col-' + colindex"
                  :rowspan="col.rowSpan"
                  :colspan="headersGroups.groups[col.text]"
                  :class="[col.value === 'collapse' ? 'collapse-col' : '', chosenClass(col.colIndex, -2, col.rowSpan, headersGroups.groups[col.text]),presentation ? 'ts-' + table.info.header_presentation_stylesid.replaceAll(',','-') : 'ts-' + table.info.header_stylesid.replaceAll(',','-')]"
                  :style="compileHeight(col.rowspan, headerStyles.height) + 'width:' + col.width + ';'"
                  @click="setSelected(col.colIndex, -2, $event)"
                  @dblclick="col.rowSpan ? sortData(col) : ''"
                >
                  <div
                    class="header-cell"
                  >
                    {{ col.text }}
                    <v-icon
                      v-if="colSortDir[col.value]"
                      x-small
                    >
                      {{ colSortDir[col.value] === 'desc' ? 'mdi-arrow-down-box' : 'mdi-arrow-up-box' }}
                    </v-icon>
                  </div>
                </td>
                <td
                  v-if="haveDelRow && canEdit && !presentation"
                  style="width: 26px;"
                  rowspan="2"
                  :class="presentation ? 'ts-' + table.info.header_presentation_stylesid.replaceAll(',','-') : 'ts-' + table.info.header_stylesid.replaceAll(',','-')"
                  :style="compileHeight(2, headerStyles.height)"
                >
                  <div
                    class="header-cell"
                  />
                </td>
              </tr>
              <tr
                slot="header"
                ref="fixedHeaderTr3"
              >
                <td
                  v-for="col in headersCols"
                  :id="'cell-' + table.info.id + '-' + col.colIndex + '--1'"
                  :key="col.value+''"
                  :rowspan="col.rowSpan"
                  :colspan="col.colSpan"
                  :class="[chosenClass(col.colIndex, -1),presentation ? 'ts-' + table.info.header_presentation_stylesid.replaceAll(',','-') : 'ts-' + table.info.header_stylesid.replaceAll(',','-')]"
                  :style="compileHeight(col.rowspan, headerStyles.height) + 'width:' + col.width + ';'"
                  @click="setSelected(col.colIndex, -1, $event)"
                  @dblclick="sortData(col)"
                >
                  <div
                    class="header-cell"
                  >
                    {{ col.text }}
                    <v-icon
                      v-if="colSortDir[col.value]"
                      x-small
                    >
                      {{ colSortDir[col.value] === 'desc' ? 'mdi-arrow-down-box' : 'mdi-arrow-up-box' }}
                    </v-icon>
                  </div>
                </td>
              </tr>
            </template>
            <template
              v-for="(item, index) in rows"
            >
              <tr
                v-if="table.row[index] && checkCollapse(index) && !(tableFilters.rows[table.info.pltmpltableid]
                && !tableFilters.rows[table.info.pltmpltableid].includes(table.row[index].plrowid))
                && table.row[index].flgvisible !== 0"
                :key="'rows-' + index"
                :class="(table.row[index].flgdynamic === 1 && canEdit && table.row[index].id) ? 'can-reorder' : ''"
                :style="`height: ${getRowHeight(index)};`"
              >
                <template v-if="rowGroups && !print">
                  <td
                    v-for="level in maxLevel"
                    :key="level"
                    style="height: 1px; position: relative; border: none!important; text-align: center;"
                  >
                    <template>
                      <v-btn
                        v-if="rowGroups && rowGroups[table.row[index].plrowid] && levels[table.row[index].plrowid] + 1 === level"
                        icon
                        text
                        x-small
                        depressed
                        class="pl-0"
                        max-width="18"
                        max-height="18"
                        @click="collapse(table.row[index].plrowid)"
                      >
                        <v-icon>
                          {{ rowGroups[table.row[index].plrowid].status == 'o' ? ' mdi-minus-box-outline' : 'mdi-plus-box-outline' }}
                        </v-icon>
                      </v-btn>
                      <div
                        v-else-if="rowGroups && table.row[index].plgroupparentrowid && ((rowGroups[table.row[index].plgroupparentrowid].parent && levels[table.row[index].plrowid] >= level) || levels[table.row[index].plrowid] == level)"
                        style="width: 100%; overflow: unset;"
                        :style="`height: ${getRowHeight(index)};`"
                      >
                        <svg
                          style="display:block; position: absolute; width: 100%; height: 110%; top: -1px;"
                        >
                          <line
                            x1="50%"
                            :y1="rowGroups[table.row[index].plgroupparentrowid].minIndex === table.row[index].sortindex && rowGroups[table.row[index].plgroupparentrowid].sortindex > table.row[index].sortindex && levels[table.row[index].plrowid] == level ? '50%' : '-5%'"
                            x2="50%"
                            :y2="rowGroups[table.row[index].plgroupparentrowid].maxIndex === table.row[index].sortindex
                              && rowGroups[table.row[index].plgroupparentrowid].sortindex < table.row[index].sortindex
                              && levels[table.row[index].plrowid] == level ? '50%' : '105%'"
                            style="stroke: rgb(119,119,119);stroke-width: 1px;"
                          />
                          <line
                            v-if="((rowGroups[table.row[index].plgroupparentrowid].maxIndex === table.row[index].sortindex && rowGroups[table.row[index].plgroupparentrowid].maxIndex > rowGroups[table.row[index].plgroupparentrowid].sortindex) || (rowGroups[table.row[index].plgroupparentrowid].minIndex === table.row[index].sortindex && rowGroups[table.row[index].plgroupparentrowid].minIndex < rowGroups[table.row[index].plgroupparentrowid].sortindex)) && levels[table.row[index].plrowid] == level"
                            x1="50%"
                            y1="50%"
                            x2="100%"
                            y2="50%"
                            style="stroke: rgb(119,119,119);stroke-width: 1px;"
                          />
                        </svg>
                      </div>
                    </template>
                  </td>
                </template>
                <template v-for="(col, colindex) in headersPerform">
                  <td
                    v-if="item[col.value].id === null || item[col.value].id !== selectedCell"
                    :id="'cell-' + table.info.id + '-' + colindex + '-' + index"
                    :key="col.value"
                    :class="[
                      presentation ? 'ts-' + item[col.value].styles_presentation : 'ts-' + item[col.value].styles,
                      !!item[col.value].flgrightsadvanced ? 'triangle': '',
                      canEdit && item[col.value].can_edit ? 'canEdit': '',
                      chosenClass(colindex,index),
                      lastModifiedId.includes(item[col.value].id) ? 'last-modified-td' : ''
                    ]"
                    style="height: 1px; position: relative"
                    @click="[checkEdit(item[col.value],colindex, index, $event), selectedCellInput = true]"
                    @contextmenu="showContext(item[col.value],colindex, index, $event)"
                    @mouseenter="$emit('showhint', item[col.value])"
                  >
                    <div
                      :class="{'last-modified': lastModifiedId.includes(item[col.value].id)}"
                      :style="`height: ${getRowHeight(index)};`"
                      style="height: 100%; width: 100%; overflow: hidden"
                    >
                      {{ item[col.value].masked_value }}
                      <v-btn
                        v-if="item[col.value].can_edit && item[col.value].colpllistid && !!table.row[index].flgdynamic && !presentation"
                        x-small
                        dark
                        icon
                        style="position: absolute; top: 4px; right: 4px;"
                        :color="'blue-grey lighten-3'"
                        @click.stop="openList(item[col.value])"
                      >
                        <v-icon>
                          mdi-chevron-down
                        </v-icon>
                      </v-btn>
                    </div>
                  </td>
                  <td
                    v-else
                    :id="'cell-' + table.info.id + '-' + colindex + '-' + index"
                    :key="col.value"
                    style="height: 1px; position: relative"
                    class="cell chosen"
                  >
                    <div
                      :style="`height: ${getRowHeight(index)};`"
                      style="height: 100%; width: 100%; overflow: hidden"
                    >
                      <input
                        :ref="'inputfield-'+item[col.value].id"
                        autofocus
                        :value="item[col.value].main_value"
                        class="cell-input withoutbg"
                        :type="item[col.value].mask && item[col.value].mask[1] === 'd' ? 'date' : ''"
                        @input="inputCheck($event, item[col.value])"
                        @blur="inputOut(item[col.value])"
                        @change="changeCellValue"
                      >
                    </div>
                  </td>
                </template>
                <td
                  v-if="haveDelRow && canEdit && !presentation"
                  style="border: 1px solid black;"
                >
                  <v-btn
                    v-if="!!table.row[index].can_del"
                    icon
                    tile
                    text
                    small
                    class="pl-0"
                    @click="wantDelRow(index)"
                  >
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </draggable>
        </table>
      </div>
    </div>
    <div v-else-if="table.info.id">
      <v-skeleton-loader
        type="table-heading, image"
      />
    </div>
    <div v-else-if="false">
      Что бы получить данные из этой таблицы, воспользуйтесь фильтром
    </div>
    <div
      v-if="false"
      class="select-wrapper"
    >
      <div class="select-container">
        <v-autocomplete
          v-for="(item, index) in selectDynamicDate"
          :key="item.id"
          v-model="selectPrefilter[index].selectData"
          class="pt-0 mb-0"
          hide-details
          :items="item.listEl"
          item-text="title"
          item-value="listitemdataid"
          multiple
          :label="item.title"
        >
          <template #prepend-item>
            <v-list-item
              ripple
              @mousedown.prevent
              @click="toggleCols"
            >
              <v-list-item-action>
                <v-icon :color="selectPrefilter && selectPrefilter.length > 0 ? 'indigo darken-4' : ''">
                  {{ iconCols }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Выбрать все
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
          <template #selection="{ item, index }">
            <v-chip
              v-if="index === 0"
              x-small
            >
              <span>{{ item.title.substring(0, 6) }}{{ item.title.length > 6 ? '...' : '' }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ selectPrefilter.length - 1 }} других)
            </span>
          </template>
        </v-autocomplete>
      </div>
      <v-btn
        color="#48C666"
      >
        Применить
      </v-btn>
    </div>
    <v-dialog
      v-if="objectsDialog"
      v-model="objectsDialog"
      width="560"
    >
      <v-card>
        <v-card-title>
          {{ objectsTitle }}
        </v-card-title>
        <v-card-text class="d-flex">
          <v-autocomplete
            ref="selects"
            v-model="popupItem.pllistitemdataid"
            :items="objectsList"
            item-value="listitemdataid"
            item-text="searchString"
            dense
            flat
            hide-no-data
            hide-details
            full-width
            clearable
            autofocus
            class="withoutbg mt-0 pl-2"
            @change="setEditedAndObjectid($event)"
            @keydown.enter.native.stop.prevent="updateListObject"
          >
            <!--        @keydown.enter="keyEnter($event)"-->
            <template #item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item" />
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.title }} <i>(id:{{ data.item.listitemdataid }})</i>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <span v-if="listNotStatic">
            <v-btn
              v-if="popupItem && dev"
              dark
              icon
              :color="'blue-grey lighten-3'"
              @click="showVirtualObjectDialog()"
            >
              <v-icon large>
                mdi-plus-box
              </v-icon>
            </v-btn>
            <v-btn
              v-if="popupItem.pllistitemdataid && canEditObject(popupItem.pllistitemdataid)"
              dark
              icon
              :color="'blue-grey lighten-3'"
              @click="showVirtualObjectDialog(popupItem.pllistitemdataid)"
            >
              <v-icon large>
                mdi-pencil-box
              </v-icon>
            </v-btn>
            <v-btn
              v-if="popupItem.pllistitemdataid && canEditObject(popupItem.pllistitemdataid)"
              dark
              icon
              :color="'blue-grey lighten-3'"
              @click="delVirtualObject(popupItem.pllistitemdataid)"
            >
              <v-icon large>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="updateListObject"
          >
            ok
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="virtualObject.show"
      v-model="virtualObject.show"
      max-width="580"
    >
      <v-card>
        <v-card-title>
          {{ virtualObject.id ? 'Изменение объекта' : 'Добавление нового объекта' }}
        </v-card-title>
        <v-card-text class="d-flex">
          <v-text-field
            v-model="virtualObject.title"
            @keydown.enter.native.stop.prevent="updateVirtualObject"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="updateVirtualObject"
          >
            {{ virtualObject.id ? 'Изменить' : 'Добавить' }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="warning"
            @click="closeVirtualObjectDialog"
          >
            отмена
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="importCSV"
      max-width="580"
    >
      <v-card>
        <v-card-title>
          Импорт данных
        </v-card-title>
        <v-card-text class="d-flex">
          <v-row>
            <v-col
              cols="12"
            >
              <v-file-input
                v-model="csvFile"
                truncate-length="15"
                accept=".csv"
                label="Добавить файл CSV"
              />
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="delimiter"
                maxlength="3"
                label="разделитель"
              />
            </v-col>
            <v-col
              cols="6"
            >
              <v-switch
                v-model="truncateTable"
                label="Очистить таблицу"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="!csvFile"
            @click="uploadFile"
          >
            Загрузить данные
          </v-btn>
          <v-spacer />
          <v-btn
            color="warning"
            @click="changeFileuploading"
          >
            отмена
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <mdialog ref="dlgDel" />
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <template v-for="(item, index) in contextMenu">
          <v-list-item
            v-if="!item.hide"
            :key="index"
            :disabled="item.disabled"
            @click="item.action()"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-group
          v-for="(subMain, index) in contextSubMenu"
          ref="listGroup"
          :key="'subMain'+index"
          class="custom-list-group"
          :class="{ 'auto-sum-disable': !autoSumContextActive }"
          :append-icon="groupIcon"
          :disabled="true"
          :value="true"
          @mouseenter="openListGroup"
          @mouseleave="closeListGroup"
        >
          <template #activator>
            <v-list-item-title>{{ subMain.title }}</v-list-item-title>
          </template>

          <v-list-item
            @mouseenter="isHoveringContextItemEnter"
            @mouseleave="isHoveringContextItemLeave"
          >
            <v-list-item
              v-for="(sub, index) in subMain.elem"
              :key="'sub'+index"
              @click="sub.action()"
            >
              <v-list-item-title>{{ sub.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-menu>
    <div v-if="!presentation">
      <table
        ref="tableCopy"
        class="table-copy"
        :style="{ left: `${tableLeft}px` }"
      >
        <tbody
          ref="pasteNodeElem"
        />
      </table>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  // import draggable from 'vuedraggable'

  function fallbackCopyTextToClipboard (text) {
    const textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')
      const msg = successful ? 'successful' : 'unsuccessful'
      console.log('Fallback: Copying text command was ' + msg)
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
  }

  export default {
    name: 'VuetyfiTableClass',
    components: {
      mdialog: () => import('../../../components/mdialog'),
      rowFilter: () => import('./components/rowFilter'),
      draggable: () => import('vuedraggable'),
    },
    props: {
      table: {
        type: Object,
        default: () => {},
      },
      print: {
        type: Boolean,
        default: false,
      },
      vcentred: {
        type: Boolean,
        default: false,
      },
      presentation: {
        type: Boolean,
        default: false,
      },
      canEdit: {
        type: Boolean,
        default: false,
      },
      showTableTitle: {
        type: Boolean,
        default: false,
      },
      rightdebug: {
        type: Boolean,
        default: false,
      },
      rightadmin: {
        type: Boolean,
        default: false,
      },
      rightadvanced: {
        type: Boolean,
        default: false,
      },
      gettingObjects: {
        type: Boolean,
        default: false,
      },
      activeTable: {
        type: Number,
        default: null,
      },
    },
    data () {
      return {
        delimiter: ';',
        truncateTable: false,
        importCSV: false,
        csvFile: null,
        parents: null,
        levels: null,
        maxLevel: 0,
        rows: null,
        rowGroups: null,
        colSortDir: {},
        contextMenu: [
          {
            title: 'копировать',
            action: () => this.copyToBuffer(),
            disabled: false,
          },
          {
            title: 'вставить',
            action: () => this.pasteFromBuffer(),
            disabled: !this.canEdit,
          },
          {
            title: 'очистить',
            action: () => this.clearCell(),
            disabled: !this.canEdit,
          },
          {
            title: 'cкрыть ячейку',
            action: () => this.cellSetHideState(),
            hide: !(this.canEdit && this.rightadvanced),
          },
          {
            title: 'показать ячейку',
            action: () => this.cellSetShowState(),
            hide: !(this.canEdit && this.rightadvanced),
          },
          {
            title: 'показать формулу',
            action: () => this.cellShowMagicFormula(),
            hide: !this.rightdebug,
          },
        ],
        contextSubMenu: [
          {
            title: 'автосумма',
            elem: [
              {
                title: 'сумма',
                action: () => this.autoSumSelectedCells('sum'),
              },
              {
                title: 'среднее',
                action: () => this.autoSumSelectedCells('average'),
              },
              {
                title: 'максимум',
                action: () => this.autoSumSelectedCells('max'),
              },
              {
                title: 'минимум',
                action: () => this.autoSumSelectedCells('min'),
              },
              {
                title: 'счетчик',
                action: () => this.autoSumSelectedCells('counter'),
              },
            ],
          },
        ],
        showMenu: false,
        x: 0,
        y: 0,
        tableInitData: null,
        showFilter: false,
        tableSnap: {},
        showInfoValue: null,
        dev: true,
        objectsTitle: null,
        popupItem: null,
        popupItemValueSnap: null,
        objectsAddDialog: false,
        objectsDialog: false,
        rowsToDelete: {},
        virtualObject: {
          id: null,
          title: null,
          show: false,
        },
        selectedCell: null, // null,
        selectedCellInput: true,
        chosenCell: null,
        rightButtonChoosen: null,
        valueСSnap: null,
        valueSnap: null,
        tableFix: false,
        modalsOpen: false,
        tableWidth: 0,
        tableLeft: 88,
        selectDynamicDate: [],
        selectPrefilter: [],
        isHoveringContextItem: false,
        groupIcon: 'mdi-chevron-up',
        autoSumContextActive: false,
        curStepChanges: 0,
        tableCancelDate: null,
        arrChangesTable: [],
        lastModifiedId: [],
        headerHeight: null,
      }
    },
    computed: {
      ...mapGetters(['tableStyles', 'plTablesChanges', 'getObjectsList', 'tableFilters']),
      iconCols () {
        if (this.likesAllCols) return 'mdi-close-box'
        if (this.likesSomeCols) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      showIfNotPrint () {
        if (this.print) {
          return false
        } else {
          const printRoute = ['/reportPdfGen']
          return !printRoute.includes(this.$route.path)
        }
      },
      chpattern () {
        let pArray = null
        if (this.table.info.chpattern) {
          pArray = JSON.parse('{' + this.table.info.chpattern + '}')
        }
        return pArray
      },
      haveDelRow () {
        let haveRowtodel = false
        this.table.row.forEach((row) => {
          if (row.can_del === 1) {
            haveRowtodel = true
          }
        })
        return haveRowtodel
      },
      listNotStatic () {
        if (this.popupItem?.pldcolid) {
          const col = this.table.col.find(x => x.plcolid === this.popupItem.pldcolid)
          if (col.pllistflgeditable == 1) {
            return false
          } else {
            return true
          }
        }
        return false
      },
      headerStyles () {
        let styleString = ''
        const styleObj = {}
        const styles = this.presentation ? this.table.info.header_presentation_stylesid || this.table.info.header_stylesid : this.table.info.header_stylesid
        let innerStyleObj = null
        let additionClass = null
        if (styles) {
          const styleArr = styles.toString().split(',')
          styleArr.forEach((style) => {
            for (const key in this.tableStyles[style]) {
              if (key == 'pl-vertical-align') {
                if (!additionClass) additionClass = {}
                if (this.tableStyles[style][key] === 'middle') {
                  additionClass = 'v-middle'
                  styleObj.position = 'relative'
                } else if (this.tableStyles[style][key] === 'bottom') {
                  additionClass = 'v-bottom'
                  styleObj.position = 'relative'
                }
              } else if (key == 'padding') {
                if (!innerStyleObj) innerStyleObj = {}
                innerStyleObj[key] = this.tableStyles[style][key]
              } else {
                styleObj[key] = this.tableStyles[style][key]
              }
            }
          })
          styleString = JSON.stringify(styleObj)
            .replace(/,/g, ';')
            .replace(/"/g, '')
            .replace('{', '')
            .replace('}', '')
        }
        const height = this.table.info?.headerheight?.replace('px', '')
        return { styleString, innerStyleObj, additionClass, height }
      },
      objectsList () {
        if (!this.popupItem) return {}
        const objList = this.$store.getters.getObjectsList[this.popupItem.colpllistid].data
        if (objList) {
          objList.forEach((item) => {
            item.searchString = item.title + ' (id:' + item.objectid + ')'
          })
          return objList
        } else {
          return {}
        }
      },
      objectsListInfo () {
        if (!this.popupItem) return {}
        const objList = this.$store.getters.getObjectsList[this.popupItem.colpllistid].info
        if (objList) {
          return objList
        } else {
          return {}
        }
      },
      tableChange () {
        let haveChange = false
        if (this.plTablesChanges[this.table.info.id]) {
          Object.keys(this.plTablesChanges[this.table.info.id]).forEach((x) => {
            if (this.plTablesChanges[this.table.info.id][x] === true) {
              haveChange = true
            }
          })
        }
        return haveChange
      },
      tableStyles () {
        return this.$store.getters.tableStyles
      },
      headersHaveGroup () {
        const haveGroup = this.table.col.find(x => x.grouptitle)
        return !!haveGroup
      },
      headersGroups () {
        const cols = []
        const groups = {}
        if (this.headersHaveGroup) {
          this.table.col.forEach((col, index) => {
            if (col.grouptitle) {
              if (groups[col.grouptitle] && !(!col.flgpresentvisible && this.presentation)) {
                groups[col.grouptitle]++
              } else if (!(!col.flgpresentvisible && this.presentation)) {
                groups[col.grouptitle] = 1
                cols.push({
                  value: col.id,
                  colstyles: col.colstyles,
                  width: col.width,
                  text: col.grouptitle,
                  grouptitle: col.grouptitle,
                  colIndex: index,
                })
              }
            } else {
              cols.push({
                value: col.id,
                text: col.title,
                colstyles: col.colstyles,
                rowSpan: 2,
                width: col.width,
                colIndex: index,
              })
            }
          })
          // if (this.haveDelRow && this.canEdit) {
          //   cols.push({
          //     value: 'del',
          //     text: '',
          //     colstyles: cols[0].colstyles,
          //     rowSpan: 2,
          //     width: '30px',
          //   })
          // }
          // if (this.rowGroups && !this.presentation) {
          //   cols.unshift({
          //     value: 'collapse',
          //     text: '',
          //     colstyles: 'collapse-col',
          //     rowspan: cols[0].rowspan,
          //     width: '20px',
          //   })
          // }
        }
        return { cols: cols, groups: groups }
      },
      headersCols () {
        const cols = []
        if (this.headersHaveGroup) {
          this.table.col.forEach((col, index) => {
            if (col.grouptitle && !(!col.flgpresentvisible && this.presentation)) {
              cols.push({
                value: col.id,
                text: col.title,
                colstyles: col.colstyles,
                width: col.width,
                grouptitle: col.grouptitle,
                colIndex: index,
                datatype: col.datatype,
              })
            }
          })
        }
        return cols
      },
      headersPerform () {
        const cols = []
        this.table.col.forEach((col) => {
          if (!(!col.flgpresentvisible && this.presentation)) {
            if (!this.tableFilters.cols[this.table.info.id] || !(this.tableFilters.cols[this.table.info.id] && !this.tableFilters.cols[this.table.info.id].includes(col.id))) {
              cols.push({
                value: col.id,
                text: col.title,
                colstyles: col.colstyles,
                rowspan: col.rowspan,
                width: col.width,
                grouptitle: col.grouptitle,
                datatype: col.datatype,
              })
            }
          }
        })
        // let delWidth = '30px'
        // if (this.tableFilters.cols[this.table.info.pltmpltableid]) {
        //   delWidth = '1%'
        // }
        // if (this.haveDelRow && this.canEdit && !this.presentation) {
        //   cols.push({
        //     value: 'del',
        //     text: '',
        //     colstyles: cols[0].colstyles,
        //     rowspan: cols[0].rowspan,
        //     width: delWidth,
        //   })
        // }
        // if (this.rowGroups && !this.presentation) {
        //   cols.unshift({
        //     value: 'collapse',
        //     text: '',
        //     colstyles: 'collapse-col',
        //     rowspan: cols[0].rowspan,
        //     width: '20px',
        //   })
        // }
        return cols
      },
      sCols () {
        if (this.chosenCell && this.chosenCell.colIndexEnd !== null) {
          return [this.chosenCell.colIndex, this.chosenCell.colIndexEnd].sort((a, b) => { return a - b })
        } else {
          return null
        }
      },
      sRows () {
        if (this.chosenCell && this.chosenCell.rowIndexEnd !== null) {
          return [this.chosenCell.rowIndex, this.chosenCell.rowIndexEnd].sort((a, b) => { return a - b })
        } else {
          return null
        }
      },
      colsInPx () {
        let colsPx = true
        let fullWidthPx = 0
        this.table.col.forEach((col) => {
          if (col.width && col.width.includes('%')) {
            colsPx = false
          } else if (col.width && col.width.includes('px')) {
            fullWidthPx += col.width.replace('px')
          } else {
            colsPx = false
          }
        })
        return { state: colsPx, width: fullWidthPx }
      },
    },
    watch: {
      'table.data' (newVal) {
        if (newVal) {
          let rows = null
          rows = []
          this.table.data.forEach((row, indx) => {
            rows[indx] = {}
            for (const col in row) {
              rows[indx][col] = row[col]
            }
          })
          this.rows = rows
        }
      },
      rows () {
        let changes = 0
        const first = this.table.col.find(x => x.numcol === 1)?.id || this.table.col[0]?.id
        if (!first) {
          console.log('this.AAAAAAAAAAAA', this.table)
        }
        this.rows.forEach((y, indx) => {
          if ((JSON.stringify(y[first]) !== JSON.stringify(this.table.data[indx][first])) && !!this.table.row[indx].flgdynamic) {
            y[first].newsortindex = indx + 1
            if (y[first].status !== 'new') {
              y[first].status = 'reorder'
            }
            changes++
            this.$store.dispatch('setPlTablesChanges', {
              table: this.table.info.id,
              cell: 'reorder',
              changestate: true,
            })
          }
        })
        if (changes === 0) {
          this.$store.dispatch('setPlTablesChanges', {
            table: this.table.info.id,
            cell: 'reorder',
            changestate: false,
          })
        }
      },
      showMenu (newVal) {
        if (!newVal) {
          setTimeout(() => { this.modalsOpen = false }, 100)
        }
      },
      activeTable: function (newVal) {
        if (newVal !== this.table.info.id) {
          this.chosenCell = null
          this.selectedCell = null
          window.removeEventListener('keydown', this.keyCommands)
        }
      },
      objectsDialog: function (newVal) {
        if (!newVal) {
          this.popupItem = null
          setTimeout(() => { this.modalsOpen = false }, 100)
        }
      },
      chosenCell: {
        handler () {
          this.autoSumContextActive = false
          if (this.chosenCell?.rowIndexEnd || this.chosenCell?.colIndexEnd) {
            this.autoSumContextActive = true
          }
        },
        deep: true,
      },
    },
    mounted () {
      let rows = null
      rows = []
      this.table.data.forEach((row, indx) => {
        rows[indx] = {}
        for (const col in row) {
          rows[indx][col] = row[col]
        }
      })
      let rowGroups = null
      this.table.row.forEach((r) => {
        if (r.plgroupparentrowid) {
          if (!rowGroups) rowGroups = {}
          if (!rowGroups[r.plgroupparentrowid]) rowGroups[r.plgroupparentrowid] = { child: [] }
          rowGroups[r.plgroupparentrowid].status = 'o'
          rowGroups[r.plgroupparentrowid].child.push(r.plrowid)
          if (!rowGroups[r.plgroupparentrowid].maxIndex || rowGroups[r.plgroupparentrowid].maxIndex <= r.sortindex) {
            rowGroups[r.plgroupparentrowid].maxIndex = r.sortindex
          }
          if (!rowGroups[r.plgroupparentrowid].minIndex || rowGroups[r.plgroupparentrowid].minIndex >= r.sortindex) {
            rowGroups[r.plgroupparentrowid].minIndex = r.sortindex
          }
        } else {
          r.plgroupparentrowid = 0
        }
      })
      if (rowGroups) {
        for (const key in rowGroups) {
          const parentRow = this.table.row.find(x => x.plrowid == key)
          rowGroups[key].sortindex = parentRow.sortindex
          rowGroups[key].child.forEach((parentRowId) => {
            if (rowGroups[parentRowId]) {
              rowGroups[key].child = [...rowGroups[key].child, ...rowGroups[parentRowId].child]
              rowGroups[parentRowId].parent = key
              // if (rowGroups[key].maxIndex > rowGroups[parentRowId].maxIndex) rowGroups[key].maxIndex = rowGroups[parentRowId].maxIndex
              // if (rowGroups[key].minIndex > rowGroups[parentRowId].minIndex) rowGroups[key].minIndex = rowGroups[parentRowId].minIndex
            }
          })
          if (parentRow && !!parentRow.flgcollapsed) {
            rowGroups[key].status = 'c'
            rowGroups[key].child.forEach((rowId) => {
              if (rowGroups[rowId]) {
                rowGroups[rowId].status = rowGroups[rowId].status + 'l'
              }
            })
          }
        }
      }
      if (rowGroups) {
        // ------
        const levels = {}
        // eslint-disable-next-line no-return-assign
        this.table.row.map(o => ({
          ...o,
          level: levels[o.plrowid] = o.plgroupparentrowid in levels
            ? levels[o.plgroupparentrowid] + 1
            : 0,
        }))
        // eslint-disable-next-line no-return-assign
        this.table.row.map(o => ({
          ...o,
          level: levels[o.plrowid] = o.plgroupparentrowid in levels
            ? levels[o.plgroupparentrowid] + 1
            : 0,
        }))
        const arr = Object.values(levels)
        this.levels = levels
        this.maxLevel = Math.max(...arr) + 1
        const parentByLVL = []
        for (const RowsID in levels) {
          if (rowGroups[RowsID]) {
            if (!parentByLVL[levels[RowsID]]) parentByLVL[levels[RowsID]] = []
            parentByLVL[levels[RowsID]].push(RowsID)
          }
        }
        this.parents = parentByLVL
      }
      this.rows = rows
      this.rowGroups = rowGroups
      this.tableInitData = JSON.stringify(this.table.data)
      this.tableCancelDate = JSON.stringify(this.table.data)
      window.addEventListener('scroll', this.scrollHandler)
      if (!this.presentation) {
        setTimeout(() => {
          this.copyHeaderTable()
        }, 100)
      }
      this.resizeHandler()
      window.addEventListener('resize', this.resizeHandler)
      window.addEventListener('resize', this.recalcFixHeader)
      window.addEventListener('resize', this.headerSpaceTable)
      if (this.table.info.flgfilterdata) {
        this.table.col.forEach((item, id) => {
          if (item.datatype === 'String') {
            this.$store.dispatch('getObjectList', item.pllistdataid).then((res) => {
              const selectsList = {
                id: item.id,
                title: item.title,
                listEl: res,
              }
              this.selectDynamicDate.push(selectsList)
              const objPrefilterItems = {
                colId: item.id,
                selectData: [],
              }
              this.selectPrefilter.push(objPrefilterItems)
            })
          }
        })
      }
    },
    beforeDestroy () {
      if (this.chosenCell) {
        this.chosenCell = null
        this.selectedCell = null
        window.removeEventListener('keydown', this.keyCommands)
        this.$emit('changeActiveTable', null)
      }
      window.removeEventListener('scroll', this.scrollHandler)
      window.removeEventListener('resize', this.resizeHandler)
      window.removeEventListener('resize', this.recalcFixHeader)
      window.removeEventListener('resize', this.headerSpaceTable)
      this.cleanFixHeader()
    },
    methods: {
      changeCellValue () {
        setTimeout(() => {
          if (this.curStepChanges === 0 && this.arrChangesTable.length > 1) { // если были изменения, и их откатили стрелочками до начала и сделали новое изменение, обнуляем старые
            this.arrChangesTable = []
            this.tableCancelDate = JSON.stringify(JSON.parse(this.tableInitData))
          }
          const table = this.table.data
          const tableCancelDate = JSON.parse(this.tableCancelDate)
          if (this.curStepChanges === 0) {
            this.arrChangesTable = []
            this.lastModifiedId = []
          }
          const mas = []
          for (const row in tableCancelDate) {
            for (const cell in tableCancelDate[row]) {
              if (tableCancelDate[row][cell].main_value !== table[row][cell].main_value) {
                mas.push({
                  id: table[row][cell].id,
                  main_value: table[row][cell].main_value,
                  masked_value: table[row][cell].main_value,
                  last_main_value: tableCancelDate[row][cell].main_value,
                  last_masked_value: tableCancelDate[row][cell].masked_value,
                  datamask: table[row][cell].datamask,
                  lastModified: true,
                })
              }
            }
          }
          this.lastModifiedId = []
          if (mas.length) {
            mas.forEach(item => {
              this.lastModifiedId.push(item.id)
            })
            this.arrChangesTable.push(mas)
            this.curStepChanges++
            this.tableCancelDate = JSON.stringify(table)
          }
          if (this.curStepChanges !== this.arrChangesTable.length) {
            const deleteCount = this.arrChangesTable.length - this.curStepChanges
            this.arrChangesTable.splice(-deleteCount)
          }
        }, 0)
      },
      changeCellById (mas, direction) {
        const table = this.table.data
        this.lastModifiedId = []
        for (const row in table) {
          for (const cell in table[row]) {
            mas.forEach(item => {
              if (item.id === table[row][cell].id) {
                if (direction === 'back') {
                  table[row][cell].main_value = item.last_main_value
                  table[row][cell].masked_value = item.last_masked_value
                  this.lastModifiedId.push(item.id)
                } else if (direction === 'forward') {
                  table[row][cell].main_value = item.main_value
                  table[row][cell].masked_value = item.masked_value
                  this.lastModifiedId.push(item.id)
                }
                if (item.datamask?.includes('p')) {
                  table[row][cell].main_value = table[row][cell].main_value * 100
                }
              }
            })
          }
        }
      },
      getCellByMasId (masId) {
        const table = this.table.data
        for (const row in table) {
          for (const cell in table[row]) {
            for (let i = 0; i < masId.length; i++) {
              if (table[row][cell].id === masId[i]) {
                return table[row][cell]
              }
            }
          }
        }
      },
      changeBack () {
        this.curStepChanges = this.curStepChanges - 1
        this.changeCellById(this.arrChangesTable[this.curStepChanges], 'back')
        const masId = []
        if (this.arrChangesTable[this.curStepChanges] !== undefined) {
          this.arrChangesTable[this.curStepChanges].forEach(item => {
            masId.push(item.id)
          })
          this.inputOut(this.getCellByMasId(masId))
        }
      },
      changeForward () {
        this.changeCellById(this.arrChangesTable[this.curStepChanges], 'forward')
        this.curStepChanges = this.curStepChanges + 1
        const masId = []
        if (this.arrChangesTable[this.curStepChanges - 1] !== undefined) {
          this.arrChangesTable[this.curStepChanges - 1].forEach(item => {
            masId.push(item.id)
          })
          this.inputOut(this.getCellByMasId(masId))
        }
      },
      openAddChartModal (id) {
        this.$store.dispatch('setOpenAddChartModal', { open: true, tableId: id, updateTableById: false })
      },
      openListGroup () {
        this.$refs.listGroup.isActive = true
        this.groupIcon = 'mdi-chevron-down'
      },
      closeListGroup () {
        if (this.isHoveringContextItem) {
          this.$refs.listGroup.isActive = false
        }
        this.groupIcon = 'mdi-chevron-up'
      },
      isHoveringContextItemEnter () {
        this.isHoveringContextItem = true
        this.groupIcon = 'mdi-chevron-down'
      },
      isHoveringContextItemLeave () {
        this.isHoveringContextItem = false
      },
      copyHeaderTable () {
        if (this.$refs.fixedHeaderTr) {
          this.headerHeight = this.$refs.fixedHeaderTr.offsetHeight
          const copyTable = this.$refs.fixedHeaderTr.cloneNode(true)
          const children = copyTable.children
          for (const child of children) {
            if (child.classList.contains('collapse-col')) {
              child.addEventListener('click', this.showLevelEvent)
            }
          }
          this.$refs.pasteNodeElem.appendChild(copyTable)
          this.$refs.pasteNodeElem.classList.add('append-el')
        }
        if (this.$refs.fixedHeaderTr2 && this.$refs.fixedHeaderTr3) {
          this.headerHeight = this.$refs.fixedHeaderTr2.offsetHeight + this.$refs.fixedHeaderTr3.offsetHeight
          const copyTable2 = this.$refs.fixedHeaderTr2.cloneNode(true)
          this.$refs.pasteNodeElem.appendChild(copyTable2)

          const copyTable3 = this.$refs.fixedHeaderTr3.cloneNode(true)
          this.$refs.pasteNodeElem.appendChild(copyTable3)
        }
      },
      headerSpaceTable () {
        if (this.$vuetify.breakpoint.width > 960) {
          this.tableLeft = 88
          return
        }
        this.tableLeft = 32
      },
      recalcFixHeader () {
        this.cleanFixHeader()
        if (this.$refs.pasteNodeElem) {
          this.$refs.pasteNodeElem.innerHTML = ''
        }
        this.copyHeaderTable()
      },
      cleanFixHeader () {
        if (!this.$refs.fixedHeaderTr) {
          return
        }
        const children = this.$refs.fixedHeaderTr.children
        for (const child of children) {
          if (child.classList.contains('collapse-col')) {
            child.removeEventListener('click', this.showLevelEvent)
          }
        }
      },
      showLevelEvent (event) {
        const curLvl = parseInt(event.target.innerText)
        this.showLevel(curLvl)
      },
      resizeHandler () {
        this.headerSpaceTable()
        this.tableWidth = this.$refs.wrapperWidth?.getBoundingClientRect().width
        if (!this.presentation) {
          this.$refs.tableCopy.style.width = this.colsInPx.state ? this.colsInPx.width + 'px' : `${this.tableWidth}px`
        }
      },
      scrollHandler () {
        const tableWrapperBottom = this.$refs.tableWrapper.getBoundingClientRect().bottom
        const tableWrapperTop = this.$refs.tableWrapper?.getBoundingClientRect().top
        if (tableWrapperTop < 110 && tableWrapperBottom > 110 + this.headerHeight) {
          this.$refs.tableWrapper.classList.add('table-wrapper')
        } else this.$refs.tableWrapper.classList.remove('table-wrapper')
        this.resizeHandler()
      },
      keyCommands (e) {
        // edit mode
        if (this.selectedCell) {
          switch (e.keyCode) {
            case 38: // upArrow
              console.log('this.selectedCellInput', this.selectedCellInput)
              if (!this.selectedCellInput) {
                e.preventDefault()
                this.selectedCell = null
                if (this.rows.length - 1 > this.chosenCell.rowIndex) {
                  do {
                    this.chosenCell.rowIndex = this.chosenCell.rowIndex - 1
                  } while (!this.checkCollapse(this.chosenCell.rowIndex))
                }
              }
              break
            case 40: // downArrow
              if (!this.selectedCellInput) {
                e.preventDefault()
                this.selectedCell = null
                if (this.rows.length - 1 > this.chosenCell.rowIndex) {
                  this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
                }
              }
              break
            case 13: // enter
              e.preventDefault()
              this.selectedCell = null
              if (this.rows.length - 1 > this.chosenCell.rowIndex) {
                this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
              } else if (this.headersPerform.length - 1 > this.chosenCell.colIndex) {
                this.chosenCell.rowIndex = 0
                this.chosenCell.colIndex = this.chosenCell.colIndex + 1
              }
              break
            case 37: // leftArrow
              if (!this.selectedCellInput) {
                e.preventDefault()
                this.selectedCell = null
                if (this.headersPerform.length - 1 > this.chosenCell.colIndex) {
                  this.chosenCell.colIndex = this.chosenCell.colIndex - 1
                }
              }
              break
            case 39: // rightArrow
              if (!this.selectedCellInput) {
                e.preventDefault()
                this.selectedCell = null
                if (this.headersPerform.length - 1 > this.chosenCell.colIndex) {
                  this.chosenCell.colIndex = this.chosenCell.colIndex + 1
                }
              }
              break
            case 9: // tab
              e.preventDefault()
              this.selectedCell = null
              if (this.headersPerform.length - 1 > this.chosenCell.colIndex) {
                this.chosenCell.colIndex = this.chosenCell.colIndex + 1
              } else if (this.rows.length - 1 > this.chosenCell.rowIndex) {
                this.chosenCell.colIndex = 0
                this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
              }
              break
            case 27: // esc
              e.preventDefault()
              this.rows[this.chosenCell.rowIndex][this.headersPerform[this.chosenCell.colIndex].value].main_value = this.valueSnap
              this.selectedCell = null
              break
            case 90: // 'z'
              if (e.ctrlKey && this.curStepChanges !== 0 && !e.shiftKey) {
                this.changeBack()
              } else if (e.ctrlKey && e.shiftKey && !(this.arrChangesTable.length === this.curStepChanges)) {
                this.changeForward()
              }
              break
            default:
              break
          }
          // highlight mode
        } else if (!this.objectsDialog) {
          e.preventDefault()
          switch (e.keyCode) {
            case 8: // backspace
            case 46: // del
              this.clearCell()
              break
            case 13: // enter
              this.checkAndFocusCell(this.rows[this.chosenCell.rowIndex][this.headersPerform[this.chosenCell.colIndex].value])
              break
            case 9: // tab
              if (this.headersPerform.length - 1 > this.chosenCell.colIndex) {
                this.chosenCell.colIndex = this.chosenCell.colIndex + 1
              } else if (this.rows.length - 1 > this.chosenCell.rowIndex) {
                this.chosenCell.colIndex = 0
                this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
              }
              break
            case 37: // ArrowLeft
              if (this.chosenCell.colIndex > 0 && !e.shiftKey) {
                if (this.chosenCell.rowIndex < -1 && this.headersPerform[this.chosenCell.colIndex].grouptitle !== '') {
                  const grouptitle = this.headersPerform[this.chosenCell.colIndex].grouptitle
                  const colFromGroup = this.headersGroups.cols.find(x => x.grouptitle === grouptitle)
                  if (colFromGroup.colIndex !== 0) this.chosenCell.colIndex = colFromGroup.colIndex - 1
                } else {
                  this.chosenCell.colIndex = this.chosenCell.colIndex - 1
                }
                this.chosenCell.colIndexEnd = null
                this.chosenCell.rowIndexEnd = null
              } else {
                const colIndex = this.chosenCell.colIndexEnd !== null ? this.chosenCell.colIndexEnd : this.chosenCell.colIndex
                if (colIndex > 0 && e.shiftKey) {
                  if (this.chosenCell.rowIndexEnd !== null) {
                    this.chosenCell.colIndexEnd = colIndex - 1
                  } else {
                    this.chosenCell.colIndexEnd = colIndex - 1
                    this.chosenCell.rowIndexEnd = this.chosenCell.rowIndex
                  }
                }
              }
              break
            case 38: // ArrowUP
              console.log('upp')
              if (this.chosenCell.rowIndex > (this.headersHaveGroup ? -2 : -1) && !e.shiftKey) {
                console.log(this.checkCollapse(this.chosenCell.rowIndex))
                do {
                  console.log('upp -1')
                  this.chosenCell.rowIndex = this.chosenCell.rowIndex - 1
                } while (!this.checkCollapse(this.chosenCell.rowIndex))
                this.chosenCell.colIndexEnd = null
                this.chosenCell.rowIndexEnd = null
                this.scrollToTable('cell-' + this.table.info.id + '-' + this.chosenCell.colIndex + '-' + this.chosenCell.rowIndex)
              } else {
                console.log('upp2')
                const rowIndex = this.chosenCell.rowIndexEnd !== null ? this.chosenCell.rowIndexEnd : this.chosenCell.rowIndex
                if (rowIndex > (this.headersHaveGroup ? 0 : -1) && e.shiftKey) {
                  if (this.chosenCell.rowIndexEnd !== null) {
                    do {
                      this.chosenCell.rowIndexEnd = rowIndex - 1
                    } while (!this.checkCollapse(this.chosenCell.rowIndexEnd))
                  } else {
                    this.chosenCell.colIndexEnd = this.chosenCell.colIndex
                    do {
                      this.chosenCell.rowIndexEnd = rowIndex - 1
                    } while (!this.checkCollapse(this.chosenCell.rowIndexEnd))
                  }
                  this.scrollToTable('cell-' + this.table.info.id + '-' + this.chosenCell.colIndexEnd + '-' + this.chosenCell.rowIndexEnd)
                }
              }
              break
            case 39: // ArrowRight
              if (this.headersPerform.length - 1 > this.chosenCell.colIndex && !e.shiftKey) {
                if (this.chosenCell.rowIndex < -1 && this.headersPerform[this.chosenCell.colIndex].grouptitle !== '') {
                  const grouptitle = this.headersPerform[this.chosenCell.colIndex].grouptitle
                  const colFromGroup = this.headersGroups.cols.find(x => x.grouptitle === grouptitle)
                  const span = this.headersGroups.groups[grouptitle]
                  if (colFromGroup.colIndex !== 0) this.chosenCell.colIndex = colFromGroup.colIndex + span
                } else {
                  this.chosenCell.colIndex = this.chosenCell.colIndex + 1
                }
                this.chosenCell.colIndexEnd = null
                this.chosenCell.rowIndexEnd = null
              } else {
                const colIndex = this.chosenCell.colIndexEnd !== null ? this.chosenCell.colIndexEnd : this.chosenCell.colIndex
                if (colIndex < this.headersPerform.length - 1 && e.shiftKey) {
                  if (this.chosenCell.rowIndexEnd !== null) {
                    this.chosenCell.colIndexEnd = colIndex + 1
                  } else {
                    this.chosenCell.colIndexEnd = colIndex + 1
                    this.chosenCell.rowIndexEnd = this.chosenCell.rowIndex
                  }
                }
              }
              break
            case 40: // ArrowDown
              if (this.rows.length - 1 > this.chosenCell.rowIndex && !e.shiftKey) {
                if (this.chosenCell.rowIndex < 0) {
                  if (this.headersPerform[this.chosenCell.colIndex].grouptitle === '') {
                    this.chosenCell.rowIndex = 0
                  } else {
                    do {
                      this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
                    } while (!this.checkCollapse(this.chosenCell.rowIndex))
                    // this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
                  }
                } else {
                  do {
                    this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
                  } while (!this.checkCollapse(this.chosenCell.rowIndex))
                  // this.chosenCell.rowIndex = this.chosenCell.rowIndex + 1
                }
                this.chosenCell.colIndexEnd = null
                this.chosenCell.rowIndexEnd = null
                this.scrollToTable('cell-' + this.table.info.id + '-' + this.chosenCell.colIndex + '-' + this.chosenCell.rowIndex)
              } else {
                const rowIndex = this.chosenCell.rowIndexEnd !== null ? this.chosenCell.rowIndexEnd : this.chosenCell.rowIndex
                if (rowIndex < this.rows.length - 1 && e.shiftKey) {
                  if (this.chosenCell.rowIndexEnd !== null) {
                    do {
                      this.chosenCell.rowIndexEnd = rowIndex + 1
                    } while (!this.checkCollapse(this.chosenCell.rowIndexEnd))
                    // this.chosenCell.rowIndexEnd = rowIndex + 1
                  } else {
                    do {
                      this.chosenCell.rowIndexEnd = rowIndex + 1
                    } while (!this.checkCollapse(this.chosenCell.rowIndexEnd))
                    // this.chosenCell.rowIndexEnd = rowIndex + 1
                    this.chosenCell.colIndexEnd = this.chosenCell.colIndex
                  }
                  this.scrollToTable('cell-' + this.table.info.id + '-' + this.chosenCell.colIndexEnd + '-' + this.chosenCell.rowIndexEnd)
                }
              }
              break
            case 27: // esc
              this.chosenCell = null
              window.removeEventListener('keydown', this.keyCommands)
              break
            case 67: // 'c'
              if (e.ctrlKey) {
                // this.copyDataToClipboard
                this.copyToBuffer()
              } else {
                this.checkAndFocusCell(this.rows[this.chosenCell.rowIndex][this.headersPerform[this.chosenCell.colIndex].value], e.key)
              }
              break
            case 86: // 'v'
              if (e.ctrlKey) {
                this.pasteFromBuffer()
              } else {
                this.checkAndFocusCell(this.rows[this.chosenCell.rowIndex][this.headersPerform[this.chosenCell.colIndex].value], e.key)
              }
              break
            case 90: // 'z'
              if (e.ctrlKey && this.curStepChanges !== 0 && !e.shiftKey) {
                this.changeBack()
              } else if (e.ctrlKey && e.shiftKey && !(this.arrChangesTable.length === this.curStepChanges)) {
                this.changeForward()
              }
              break
            default:
              if (e.key.length === 1 && this.chosenCell) {
                this.checkAndFocusCell(this.rows[this.chosenCell.rowIndex][this.headersPerform[this.chosenCell.colIndex].value], e.key, true)
              }
              break
          }
        }
      },
      sortData (column) {
        if (!this.table.info.flgdynamicrows) {
          return
        }
        const col = column.value
        if (!this.colSortDir[col]) {
          this.colSortDir = []
          this.colSortDir[col] = 'desc'
        } else if (this.colSortDir[col] === 'desc') {
          this.colSortDir = []
          this.colSortDir[col] = 'asc'
        } else {
          this.colSortDir = []
          this.colSortDir[col] = null
        }
        let modifier = 1
        if (this.colSortDir[col] === 'desc') modifier = -1
        if (this.colSortDir[col]) {
          this.rows.sort((a, b) => {
            const sindex = this.table.row.findIndex(x => x.id === a[col].plrowdataid)
            if (this.table.row[sindex].flgdynamic) {
              let av = a[col].main_value
              let bv = b[col].main_value
              if (a[col].main_datatype === 'Integer') {
                av = parseInt(a[col].main_value)
                bv = parseInt(b[col].main_value)
              } else if (a[col].main_datatype === 'Float') {
                av = parseFloat(a[col].main_value)
                bv = parseFloat(b[col].main_value)
              }
              if (av < bv) return -1 * modifier
              if (av > bv) return 1 * modifier
            }
            return 0
          })
        } else {
          this.rows.sort((a, b) => {
            if (a[col].sortindex < b[col].sortindex) return -1
            if (a[col].sortindex > b[col].sortindex) return 1
            return 0
          })
        }
      },
      collapse (parrentrow) {
        const state = this.rowGroups[parrentrow].status === 'o' ? 'c' : 'o'
        this.rowGroups[parrentrow].status = state
        this.rowGroups[parrentrow].child.forEach((rowId) => {
          if (this.rowGroups[rowId]) {
            if (state === 'c') {
              this.rowGroups[rowId].status = this.rowGroups[rowId].status + 'l'
            } else {
              this.rowGroups[rowId].status = this.rowGroups[rowId].status.replace('l', '')
            }
          }
        })
      },
      showLevel (level) {
        for (let lv = this.parents.length; lv > 0; lv--) {
          this.parents[lv - 1].forEach((rowId) => {
            this.rowGroups[rowId].status = lv + 1 > level ? 'c' : 'o'
          })
        }
      },
      checkCollapse (rowIndex) {
        if (!this.rowGroups) {
          return true
        } else if (this.table.row[rowIndex]) {
          const rowId = this.table.row[rowIndex].plgroupparentrowid
          if (this.rowGroups[rowId]) {
            return this.rowGroups[rowId].status === 'o'
          } else {
            return true
          }
        } else {
          return true
        }
      },
      uploadFile () {
        const payload = {
          pltabledataid: this.table.info.id,
          truncateTable: this.truncateTable,
          delimiter: this.delimiter,
          csvFile: this.csvFile,
        }
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('importCSVFile', payload).then(() => {
          this.$store.commit('clearObjectList')
          const tablesIDs = []
          tablesIDs.push(this.table.info.id)
          this.$emit('updateTablesData', tablesIDs)
          this.csvFile = null
          this.importCSV = false
        }).catch((e) => {
          this.csvFile = null
          this.importCSV = false
          console.log('Error:', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      changeFileuploading () {
        this.csvFile = null
        this.importCSV = false
      },
      cellShowMagicFormula () {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getMagicFormula', { pldataid: this.chosenCell.cellID }).then((resp) => {
          console.log('resp.length', resp.length)
          console.log('resp', resp)
          this.$store.commit('setCellFormulaData', null)
          if (resp.length > 0) {
            this.$store.commit('setCellFormulaData', resp)
            this.$store.commit('setCellFormulaShow', true)
          } else if (resp.length == 0) {
            this.$store.dispatch('setSnackbar', { state: true, text: 'Нет формулы для данной ячейки', color: 'success', direction: 'top center' })
          }
          this.$store.dispatch('setDataLoading', false)
        }).catch(() => {
          this.$store.dispatch('setDataLoading', false)
        })
      },
      pasteFromBuffer () {
        if (!navigator.clipboard) {
          console.log('не могу до буфера достучатся')
          const successful = document.execCommand('paste')
          console.log(successful)
          return
        }
        navigator.clipboard.readText()
          .then(text => {
            console.log('clipboard', text)
            if (text.includes('\t') || text.includes('\n')) {
              const buffArray = text.replaceAll('\r', '').split('\n')
              const dataArray = []
              buffArray.forEach((row) => {
                if (row.length > 0) {
                  dataArray.push(row.split('\t'))
                }
              })
              let rowIndex = this.chosenCell.rowIndexEnd !== null ? this.sRows[0] : this.chosenCell.rowIndex
              const colIndex = this.chosenCell.colIndexEnd !== null ? this.sCols[0] : this.chosenCell.colIndex
              console.log(JSON.stringify(dataArray))
              dataArray.forEach((rw, rwi) => {
                rw.forEach((cl, cli) => {
                  while (!this.checkCollapse(rowIndex + rwi)) {
                    rowIndex++
                  }
                  if (rowIndex + rwi >= 0) {
                    this.checkAndPasteCell(this.rows[rowIndex + rwi][this.headersPerform[colIndex + cli].value], cl)
                  }
                })
              })
              this.chosenCell.rowIndex = rowIndex
              this.chosenCell.rowIndexEnd = rowIndex + dataArray.length - 1
              this.chosenCell.colIndex = colIndex
              this.chosenCell.colIndexEnd = colIndex + dataArray[0].length - 1
            } else {
              this.checkAndFocusCell(this.rows[this.chosenCell.rowIndex][this.headersPerform[this.chosenCell.colIndex].value], text, false)
            }
            console.log('Pasted content: ', text)
            this.changeCellValue()
          })
          .catch(err => {
            navigator.clipboard.read().then(cb => {
              console.log('cb', cb)
            })
            console.error('Failed to read clipboard contents: ', err)
          })
        navigator.clipboard.read().then(clipboardItems => {
          for (const clipboardItem of clipboardItems) {
            for (const type of clipboardItem.types) {
              if (type === 'text/html') {
                clipboardItem.getType(type).then(b => {
                  b.text().then(bt => {
                    console.log('bt', bt)
                  })
                })
              }
            }
          }
        })
      },
      copyToBuffer () {
        let text = ''
        let html = ''
        let colsgroup = ''
        let tablewidth = 0
        if (this.sCols) {
          for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
            const textid = `cell-${this.table.info.id}-${c}-0`
            const elem = document.getElementById(textid)
            const width = Math.round(getComputedStyle(elem.children[0]).width.replace('px', ''))
            colsgroup += `<col width="${width}" style="width:${Math.round(width * 0.75)}pt;">`
            tablewidth += width
          }
          for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
            let htmlRow = ''
            for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
              const textid = `cell-${this.table.info.id}-${c}-${r}`
              const elem = document.getElementById(textid)
              if (elem) {
                const tab = c === this.sCols[1] ? '' : '\t'
                let value = r >= 0 ? this.rows[r][this.headersPerform[c].value].main_value : this.headersPerform[c].text
                if (value && r >= 0 && this.rows[r][this.headersPerform[c].value] && this.rows[r][this.headersPerform[c].value].main_datatype !== 'String') {
                  value = value.replace('.', ',')
                }
                if (value === null) {
                  value = ''
                }
                text += value + tab
                const backgroundColor = getComputedStyle(elem.children[0]).backgroundColor
                const textAlign = getComputedStyle(elem.children[0]).textAlign
                const textVAlign = getComputedStyle(elem.children[0]).verticalAlign
                const wight = Math.round(getComputedStyle(elem.children[0]).width.replace('px', ''))
                const height = Math.round(getComputedStyle(elem.children[0]).height.replace('px', ''))
                const tdStyle = 'border: 0.5pt solid rgb(51,20,20);' +
                  `background-color: ${backgroundColor};` +
                  `width: ${Math.round(wight * 0.75)}pt;` +
                  `height: ${Math.round(height * 0.75)}pt;` +
                  `text-align: ${textAlign};` +
                  `vertical-align: ${textVAlign};`
                htmlRow += `<td height="${height}" style="${tdStyle}">${value}</td>`
              }
            }
            if (htmlRow != '') {
              html += '<tr>' + htmlRow + '</tr>'
              text += '\n'
            }
          }
          colsgroup = '<colgroup>' + colsgroup + '</colgroup>'
          html = '<table width="' + tablewidth + '" cellspacing="0" cellpadding="0" dir="ltr" border="1" style="table-layout: fixed; font-size: 10pt; font-family: Arial; width: 0px; border-collapse: collapse; width:' + Math.round(tablewidth * 0.75) + 'pt">' + colsgroup + '<tbody>' + html + '</tbody></table>'
        } else {
          if (this.chosenCell.rowIndex >= 0) {
            const c = this.chosenCell.colIndex
            const r = this.chosenCell.rowIndex
            text = this.rows[r][this.headersPerform[c].value].main_value
            if (text && r >= 0 && this.rows[r][this.headersPerform[c].value] && this.rows[r][this.headersPerform[c].value].main_datatype !== 'String') {
              text = text.replace('.', ',')
            }
          } else {
            text = this.chosenCell.rowIndex < -1 ? this.headersPerform[this.chosenCell.colIndex].grouptitle : this.headersPerform[this.chosenCell.colIndex].text
          }
          const textid = `cell-${this.table.info.id}-${this.chosenCell.colIndex}-${this.chosenCell.rowIndex}`
          const elem = document.getElementById(textid)
          const backgroundColor = getComputedStyle(elem.children[0]).backgroundColor
          html = '<span  data-sheets-value="{' + text + '}" style="background-color: ' + backgroundColor + ';">' + text + '</span>'
        }
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text)
          return
        }
        // eslint-disable-next-line no-use-before-define,no-undef
        const data = [new ClipboardItem({ 'text/html': new Blob([html], { type: 'text/html' }), 'text/plain': new Blob([text], { type: 'text/plain' }) })]
        navigator.clipboard.write(data).then(() => {
          // this.$refs[]
          console.log('скопировано в буфер', html)
        })
      },
      autoSumSelectedCells (type) {
        const masValue = []
        let roundedArr = []
        let allElemIsPercent = false
        let maxNumberMask = 0
        let sumTable = 0
        if (this.sCols) {
          const masMask = []
          for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
            for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
              const textid = `cell-${this.table.info.id}-${c}-${r}`
              const elem = document.getElementById(textid)
              if (elem) {
                const cellType = this.rows[r][this.headersPerform[c].value].main_datatype
                if (cellType === 'Float' || cellType === 'Integer' || this.rows[r][this.headersPerform[c].value].datamask?.includes('p')) {
                  let value = r >= 0 ? this.rows[r][this.headersPerform[c].value].main_value : this.headersPerform[c].text
                  if (value && r >= 0 && this.rows[r][this.headersPerform[c].value] && this.rows[r][this.headersPerform[c].value].main_datatype !== 'String') {
                    value = value.replace('.', ',')
                  }
                  if (value) {
                    masValue.push(value)
                    masMask.push(this.rows[r][this.headersPerform[c].value].datamask)
                  }
                }
              }
            }
          }
          allElemIsPercent = masMask.every(element => element.charAt(1) === 'p')
          for (let i = 0; i < masMask.length; i++) {
            const str = masMask[i]
            const number = parseInt(str.slice(-1))
            if (!isNaN(number)) {
              maxNumberMask = Math.max(maxNumberMask, number)
            }
          }

          roundedArr = masValue.map(num => parseFloat(num.replace(',', '.')))
        }
        if (type === 'sum' || type === 'average') {
          sumTable = roundedArr.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0)
        }
        let text = ''
        switch (type) {
          case 'sum':
            text = allElemIsPercent ? `Сумма равна: ${(sumTable * 100).toFixed(maxNumberMask)}%` : `Сумма равна: ${sumTable.toFixed(maxNumberMask)}`
            break

          case 'average': {
            const average = sumTable / masValue.length || 0
            text = allElemIsPercent
              ? `Среднее значение равно: ${(average * 100).toFixed(maxNumberMask)}%`
              : `Среднее значение равно: ${average.toFixed(maxNumberMask)}`
            break
          }
          case 'max': {
            const maxValue = roundedArr.length > 0 ? Math.max(...roundedArr.map(parseFloat)) : 0
            text = allElemIsPercent ? `Максимальное значение: ${(maxValue * 100).toFixed(maxNumberMask)}%` : `Максимальное значение: ${maxValue.toFixed(maxNumberMask)}`
            break
          }
          case 'min': {
            const minValue = roundedArr.length > 0 ? Math.min(...roundedArr.map(parseFloat)) : 0
            text = allElemIsPercent ? `Минимальное значение: ${(minValue * 100).toFixed(maxNumberMask)}%` : `Минимальное значение: ${minValue.toFixed(maxNumberMask)}`
            break
          }

          case 'counter':
            text = `Количество: ${masValue.length}`
            break

          default:
            text = 'Неверный тип'
            break
        }
        this.$store.commit('setSnackbarAutoSum', { state: true, text: text })
      },
      clearCell () {
        if (this.sCols) {
          for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
            for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
              this.checkAndPasteCell(this.rows[r][this.headersPerform[c].value], null)
            }
          }
        } else {
          this.checkAndPasteCell(this.rows[this.chosenCell.rowIndex][this.headersPerform[this.chosenCell.colIndex].value], null)
        }
      },
      cellSetHideState () {
        const changesStrings = []
        if (this.sCols) {
          for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
            for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
              changesStrings.push(this.rows[r][this.headersPerform[c].value].id)
            }
          }
          console.log('cell', this.sCols)
        } else {
          changesStrings.push(this.rows[this.chosenCell.rowIndex][this.headersPerform[this.chosenCell.colIndex].value].id)
        }
        const payload = {
          tableID: this.table.info.id,
          visibleIDString: null,
          hideIDString: changesStrings.join(','),
        }
        this.$store.dispatch('setTableDataVisible', payload).then((resp) => {
          if (resp[0].result === 1) {
            const initData = JSON.parse(this.tableInitData)
            if (this.sCols) {
              for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
                for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
                  this.rows[r][this.headersPerform[c].value].flgrightsadvanced = 1
                  initData[r][this.headersPerform[c].value].flgrightsadvanced = 1
                }
              }
            } else {
              this.rows[this.chosenCell.rowIndex][this.headersPerform[this.chosenCell.colIndex].value].flgrightsadvanced = 1
            }
            this.tableInitData = JSON.stringify(initData)
          }
        })
      },
      cellSetShowState () {
        const changesStrings = []
        if (this.sCols) {
          for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
            for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
              changesStrings.push(this.rows[r][this.headersPerform[c].value].id)
            }
          }
          console.log('cell', this.sCols)
        } else {
          changesStrings.push(this.rows[this.chosenCell.rowIndex][this.headersPerform[this.chosenCell.colIndex].value].id)
        }
        const payload = {
          tableID: this.table.info.id,
          visibleIDString: changesStrings.join(','),
          hideIDString: null,
        }
        this.$store.dispatch('setTableDataVisible', payload).then((resp) => {
          if (resp[0].result === 1) {
            const initData = JSON.parse(this.tableInitData)
            if (this.sCols) {
              for (let r = this.sRows[0]; r <= this.sRows[1]; r++) {
                for (let c = this.sCols[0]; c <= this.sCols[1]; c++) {
                  this.rows[r][this.headersPerform[c].value].flgrightsadvanced = 0
                  initData[r][this.headersPerform[c].value].flgrightsadvanced = 0
                }
              }
            } else {
              this.rows[this.chosenCell.rowIndex][this.headersPerform[this.chosenCell.colIndex].value].flgrightsadvanced = 0
            }
            this.tableInitData = JSON.stringify(initData)
          }
        })
      },
      showContext (item, colIndex, rowIndex, e) {
        if (this.presentation) return
        e.preventDefault()
        this.modalsOpen = true
        if (this.chosenCell && this.chosenCell.colIndexEnd) {
          if (rowIndex < this.sRows[0] || rowIndex > this.sRows[1] || colIndex < this.sCols[0] || colIndex > this.sCols[1]) {
            this.chosenCell = {
              rowIndex: rowIndex,
              colIndex: colIndex,
              cellID: item.id,
              colIndexEnd: null,
              rowIndexEnd: null,
            }
          }
        } else {
          this.chosenCell = {
            rowIndex: rowIndex,
            colIndex: colIndex,
            cellID: item.id,
            colIndexEnd: null,
            rowIndexEnd: null,
          }
        }
        this.showMenu = false
        this.x = e.clientX + 10
        this.y = e.clientY + 5
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
      exportTableToExcel (tableID, filename = '') {
        const dataType = 'application/vnd.ms-excel'
        const tableSelect = document.getElementById(tableID)
        const tableHTML = tableSelect.outerHTML.replace(/ /g, '%20')

        // Specify file name
        filename = filename ? filename + '.xls' : 'excel_data.xls'

        // Create download link element
        const downloadLink = document.createElement('a')

        document.body.appendChild(downloadLink)

        if (navigator.msSaveOrOpenBlob) {
          const blob = new Blob(['\ufeff', tableHTML], { type: dataType })
          navigator.msSaveOrOpenBlob(blob, filename)
        } else {
          // Create a link to the file
          downloadLink.href = 'data:' + dataType + ', ' + tableHTML
          // Setting the file name
          downloadLink.download = filename
          // triggering the function
          downloadLink.click()
        }
      },
      reverseChanges () {
        // eslint-disable-next-line vue/no-mutating-props
        this.table.data = null
        this.$nextTick(() => {
          // eslint-disable-next-line vue/no-mutating-props
          this.table.data = JSON.parse(this.tableInitData)
          this.tableCancelDate = JSON.stringify(JSON.parse(this.tableInitData))
        })
        this.arrChangesTable = []
        this.lastModifiedId = []
        this.curStepChanges = 0
        this.$store.commit('delPlTablesChanges', this.table.info.id)
      },
      chosenClass (col, row, rowspan, colspan) {
        if (!this.chosenCell) return ''
        if (this.sRows !== null) {
          if (this.sRows[0] > row || row > this.sRows[1]) return ''
          let CSSclass = ''
          if ((this.sCols[0] <= col && col <= this.sCols[1]) && (this.sRows[0] <= row && row <= this.sRows[1])) {
            CSSclass = CSSclass + ' chosen-in'
            if (this.sCols[0] === col) {
              CSSclass = CSSclass + ' chosen-left'
            }
            if (this.sRows[0] === row) {
              CSSclass = CSSclass + ' chosen-top'
            }
            if (this.sCols[1] === col) {
              CSSclass = CSSclass + ' chosen-right'
            }
            if (this.sRows[1] === row) {
              CSSclass = CSSclass + ' chosen-bottom'
            }
            if (this.chosenCell.colIndex === col && this.chosenCell.rowIndex === row) {
              CSSclass = CSSclass + ' chosen'
            }
            return CSSclass
          } else if (row >= 0) {
            return 'chosenRow'
          }
        } else {
          let cellClass = ''
          if (rowspan) {
            cellClass = this.chosenCell && this.chosenCell.colIndex === col && [-2, -1].includes(this.chosenCell.rowIndex) ? 'chosen' : cellClass
          } else if (colspan && this.chosenCell.rowIndex < 0) {
            const cols = []
            for (let i = 1; i <= colspan; i++) {
              cols.push(col + (i - 1))
            }
            cellClass = this.chosenCell && cols.includes(this.chosenCell.colIndex) && this.chosenCell.rowIndex === row ? 'chosen' : cellClass
          } else {
            cellClass = this.chosenCell && this.chosenCell.colIndex === col && this.chosenCell.rowIndex === row ? 'chosen' : cellClass
          }
          cellClass = this.chosenCell && this.chosenCell.colIndex !== col && this.chosenCell.rowIndex === row && row >= 0 ? 'chosenRow' : cellClass
          return cellClass
        }
      },
      onClickOutside () {
        if ((this.selectedCell || this.chosenCell) && !this.modalsOpen) {
          this.selectedCell = null
          this.chosenCell = null
          window.removeEventListener('keydown', this.keyCommands)
        }
      },
      delDynTable (table) {
        this.$refs.dlgDel.open({
          title: 'Удалить таблицу?',
          acceptText: 'Удалить',
          cancelText: 'Отмена',
          dialogMaxWidth: 440,
        }).then(() => {
          this.$store.dispatch('setLoading', true)
          this.$store.dispatch('delDynTable', table.info.id).then(() => {
            this.$emit('delTable', table.info.id)
            this.$store.dispatch('setLoading', true)
          })
        })
      },
      delVirtualObject (id) {
        this.$refs.dlgDel.open({
          title: 'Удалить объект?',
          acceptText: 'Продолжить',
          cancelText: 'Отмена',
          dialogMaxWidth: 440,
        }).then(() => {
          this.$store.dispatch('delVirtualObject', { pllistitemid: id, pllistid: this.popupItem.colpllistid }).then(() => {
            const objctIndex = this.objectsList.findIndex(x => x.listitemdataid === id)
            this.objectsList.splice(objctIndex, 1)
          })
        })
      },
      closeVirtualObjectDialog () {
        this.virtualObject = {
          id: null,
          show: false,
          title: null,
        }
      },
      wantDelRow (rowIndex) {
        this.$refs.dlgDel.open({
          title: 'Удалить строку?',
          acceptText: 'Удалить',
          cancelText: 'Отмена',
          dialogMaxWidth: 440,
        }).then(() => {
          this.delRow(rowIndex)
        })
      },
      delRow (rowIndex) {
        const tableindx = this.table.info.id
        const table = this.table
        const row = this.table.data[rowIndex]
        const [first] = Object.keys(row)
        if (row[first].status !== 'new') {
          if (this.rowsToDelete[tableindx]) {
            this.rowsToDelete[tableindx].push({
              plrowdataid: row[first].plrowdataid,
              sortindex: row[first].sortindex,
              status: 'del',
              data: [],
            })
          } else {
            this.rowsToDelete[tableindx] = []
            this.rowsToDelete[tableindx].push({
              plrowdataid: row[first].plrowdataid,
              sortindex: row[first].sortindex,
              status: 'del',
              data: [],
            })
          }
          this.$store.dispatch('setPlTablesChanges', { table: tableindx, cell: 'delRow', changestate: true })
        } else {
          let newRowCount = 0
          const firstCol = table.col.find(x => x.numcol === 1)?.id || this.table.col[0].id
          table.data.forEach((row) => {
            if (row[firstCol].status === 'new') {
              newRowCount++
            }
          })
          if (newRowCount < 1) {
            this.$store.dispatch('setPlTablesChanges', { table: tableindx, cell: 'addRow', changestate: false })
          }
        }
        // eslint-disable-next-line vue/no-mutating-props
        this.table.data.splice(rowIndex, 1)
        // eslint-disable-next-line vue/no-mutating-props
        this.table.row.splice(rowIndex, 1)
        this.rowsToDelete = JSON.parse(JSON.stringify(this.rowsToDelete))
        // for (let i = 0; i < table.data.length; i++) {
        //   const first = table.col.find(x => x.numcol === 1)?.id || this.table.col[0].id
        //   if (table.data[i][first].sortindex < 1000 && table.data[i][first].sortindex !== i + 1) {
        //     table.data[i][first].sortindex = i + 1
        //     table.data[i][first].main_value = i + 1
        //     if (table.data[i][first].status !== 'new') {
        //       table.data[i][first].status = 'upd'
        //     }
        //   }
        // }
      },
      checkAndPasteCell (item, newVal) {
        console.log('listVal newVal 1', newVal)
        console.log('listVal item 1', item)
        if (item.can_edit && this.canEdit && !item.colpllistid) {
          const vs = JSON.parse(JSON.stringify(item.main_value))
          console.log('listVal newVal 1', newVal)
          console.log('listVal item 1', item)
          if (newVal && item.main_datatype !== 'String') {
            if (newVal.includes('%')) {
              newVal = newVal.replace('%', '')
            }
            if (item.datamask && item.datamask.includes('p')) {
              newVal = newVal / 100
              newVal = newVal.toString()
            }
            newVal = newVal.replaceAll(' ', '')
            newVal = newVal.replaceAll(',', '.')
          }
          this.inputCheckInitValue(newVal, item)
          this.checkUpdate(item, vs)
          item.masked_value = this.mainValueFormatted(item)
        } else {
          console.log('listVal newVal', newVal)
          console.log('listVal item', item)
          const pllistid = item.colpllistid
          if (!(this.getObjectsList[pllistid] && this.getObjectsList[pllistid].data)) {
            console.log('noItem')
            this.$store.dispatch('getObjectList', pllistid).then(() => {
              const objId = this.getObjectsList[pllistid].data.find(x => x.title === newVal)
              if (objId) {
                console.log('objid', objId)
                this.setEditedAndObjectid(objId.objectid, pllistid, item)
              }
            })
          } else {
            const objId = this.getObjectsList[pllistid].data.find(x => x.title === newVal)
            if (objId) {
              console.log('objid', objId)
              this.setEditedAndObjectid(objId.objectid, pllistid, item)
            }
          }
          // check and update selected item this.openList(item)
        }
      },
      checkAndFocusCell (item, newVal = null, mouseinput = true) {
        if (item.can_edit && this.canEdit) {
          if (newVal && item.colpllistid) {
            console.log('listVal newVal', newVal)
            console.log('listVal item', item)
            const pllistid = item.colpllistid
            if (!(this.getObjectsList[pllistid] && this.getObjectsList[pllistid].data)) {
              console.log('noItem')
              this.$store.dispatch('getObjectList', pllistid).then(() => {
                const objId = this.getObjectsList[pllistid].data.find(x => x.title === newVal)
                if (objId) {
                  console.log('objid', objId)
                  this.setEditedAndObjectid(objId.objectid, pllistid, item)
                }
              })
            } else {
              const objId = this.getObjectsList[pllistid].data.find(x => x.title === newVal)
              if (objId) {
                console.log('objid', objId)
                this.setEditedAndObjectid(objId.objectid, pllistid, item)
              }
            }
            // check and update selected item this.openList(item)
          } else {
            if (newVal && item.main_datatype !== 'String') {
              if (newVal.includes('%')) {
                newVal = newVal.replace('%', '')
                // if (item.datamask && item.datamask.includes('p')) {
                //   newVal = newVal / 100
                //   newVal = newVal.toString()
                // }
              }
              console.log('newVaal', newVal)
              newVal = newVal.replaceAll(' ', '')
              newVal = newVal.replaceAll(',', '.')
            }
            const valmask = item.datamask ? item.datamask.substr(1, 1) : ''
            if (item.main_datatype === 'Integer') {
              if (item.main_value && item.main_value.includes('.')) {
                item.main_value = item.main_value.split('.')[0]
              }
            } else if (item.main_datatype === 'Float' && valmask === 'p') {
              if (item.main_value) {
                item.main_value = item.main_value * 100
              }
            } else if (item.main_datatype === 'Float') {
              if (item.main_value) {
                item.main_value = parseFloat(item.main_value)
              }
            }
            this.valueSnap = item.main_value
            this.selectedCell = item.id
            this.selectedCellInput = mouseinput
            this.tableFix = true
            if (newVal) {
              this.inputCheckInitValue(newVal, item)
            }
            this.$nextTick(() => {
              const refName = 'inputfield-' + item.id
              this.$refs[refName][0].focus()
            })
          }
        }
        this.changeCellValue()
      },
      scrollToTable (cellID) {
        if (document.getElementById([cellID])) {
          const yOffset = -100
          const element = document.getElementById([cellID])
          if ((window.innerHeight - 10) < element.getBoundingClientRect().y) {
            const y = window.scrollY + Math.abs(yOffset)
            window.scrollTo({ behavior: 'smooth', top: y })
          } else if (element.getBoundingClientRect().y < Math.abs(yOffset)) {
            const y = window.scrollY + yOffset
            window.scrollTo({ behavior: 'smooth', top: y })
          }
        }
      },
      setSelected (colIndex, rowIndex, e) {
        if (e && (e.ctrlKey || e.shiftKey) && this.chosenCell) {
          this.chosenCell.colIndexEnd = colIndex
          this.chosenCell.rowIndexEnd = rowIndex
        } else {
          this.chosenCell = {
            rowIndex: rowIndex,
            colIndex: colIndex,
            cellID: null,
            colIndexEnd: null,
            rowIndexEnd: null,
          }
          window.addEventListener('keydown', this.keyCommands)
          this.$emit('changeActiveTable', this.table.info.id)
        }
      },
      checkEdit (item, colIndex, rowIndex, e) {
        if (!this.presentation) {
          if (!!item.can_edit && this.canEdit && this.chosenCell && this.chosenCell.colIndex === colIndex && this.chosenCell.rowIndex === rowIndex) {
            if (item.colpllistid) {
              this.openList(item)
            } else {
              const valmask = item.datamask ? item.datamask.substr(1, 1) : ''
              this.selectedCell = item.id
              if (item.main_datatype === 'Integer') {
                if (item.main_value && item.main_value.includes('.')) {
                  item.main_value = item.main_value.split('.')[0]
                }
              } else if (item.main_datatype === 'Float' && valmask === 'p') {
                if (item.main_value) {
                  item.main_value = item.main_value * 100
                }
              } else if (item.main_datatype === 'Float') {
                if (item.main_value) {
                  item.main_value = parseFloat(item.main_value)
                }
              }
              this.valueSnap = item.main_value
              this.tableFix = true
              this.$nextTick(() => {
                const refName = 'inputfield-' + item.id
                this.$refs[refName][0].focus()
              })
            }
          } else {
            if (e && (e.ctrlKey || e.shiftKey) && this.chosenCell) {
              this.chosenCell.colIndexEnd = colIndex
              this.chosenCell.rowIndexEnd = rowIndex
            } else {
              this.chosenCell = {
                rowIndex: rowIndex,
                colIndex: colIndex,
                cellID: item.id,
                colIndexEnd: null,
                rowIndexEnd: null,
              }
              window.addEventListener('keydown', this.keyCommands)
              this.$emit('changeActiveTable', this.table.info.id)
            }
          }
        }
      },
      setEditedAndObjectid (newval, colpllistid = null, item = null) {
        if (newval >= 0) {
          let valText = null
          if (colpllistid) {
            this.popupItem = item
            this.popupItem.colpllistid = colpllistid
            valText = this.getObjectsList[colpllistid].data.find(x => x.objectid === newval)
          } else {
            valText = this.objectsList.find(x => x.listitemdataid === newval)
          }
          this.popupItem.main_value = valText.title
          this.popupItem.masked_value = valText.title
        } else {
          this.popupItem.main_value = null
          this.popupItem.masked_value = null
        }
        if (this.popupItem && this.popupItem.status !== 'new' && this.popupItemValueSnap !== this.popupItem.main_value) {
          if (this.popupItem.status !== 'upd') {
            this.$store.dispatch('setPlTablesChanges', { table: this.table.info.id, cell: this.popupItem.id, changestate: true })
            this.popupItem.status = 'upd'
          }
        } else if (this.popupItemValueSnap === this.popupItem.main_value && this.popupItem.status !== 'upd') {
          this.$store.dispatch('setPlTablesChanges', { table: this.table.info.id, cell: this.popupItem.id, changestate: false })
          this.popupItem.status = null
        }
      },
      checkUpdate (value, valueSnap = this.valueSnap) {
        if (value.main_value === '-') { value.main_value = null }
        if (value && value.status !== 'new' && valueSnap !== value.main_value) {
          if (value.status !== 'upd') {
            this.$store.dispatch('setPlTablesChanges', { table: this.table.info.id, cell: value.id, changestate: true })
            value.status = 'upd'
          }
        } else if (valueSnap === value.main_value && value.status !== 'upd') {
          this.$store.dispatch('setPlTablesChanges', { table: this.table.info.id, cell: value.id, changestate: false })
          value.status = null
        }
        this.edit = false
      },
      canEditObject (id) {
        const vObj = this.objectsList.find(x => x.listitemdataid === id)
        return !!vObj.flgvirtual
      },
      openList (e) {
        if (!e.id) {
          return
        }
        if (e.can_edit === 0) {
          e.cancel = true
        } else if (e.colpllistid) {
          e.cancel = true
          const pllistid = e.colpllistid
          if (!(this.getObjectsList[pllistid] && this.getObjectsList[pllistid].data)) {
            this.$store.dispatch('getObjectList', e.colpllistid).then(() => {
              this.popupItem = e
              this.popupItemValueSnap = JSON.parse(JSON.stringify(e.main_value))
              this.objectsDialog = true
              this.modalsOpen = true
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$refs.selects.$refs.input.focus()
                  setTimeout(() => {
                    this.$refs.selects.activateMenu()
                  }, 100)
                })
              })
            })
          } else {
            this.popupItem = e
            this.popupItemValueSnap = JSON.parse(JSON.stringify(e.main_value))
            this.objectsDialog = true
            this.modalsOpen = true
          }
        }
      },
      addRow () {
        const newRow = {}
        let maxSortindex = this.table.info.new_dyn_sortindex || 1
        // let maxSortindex = 1
        let maxIndex = 0
        // console.log('maxSortIndex', maxSortindex)
        // console.log('maxIndex', maxIndex)
        this.table.row.forEach((row, index) => {
          // console.log('Row', index)
          // console.log('Row', row)
          // console.log('maxSortindex >= row.sortindex + 1', maxSortindex >= row.sortindex + 1)
          if (row.flgdynamic == 0 && maxSortindex >= row.sortindex + 1) {
            maxSortindex = row.sortindex + 1
            maxIndex = index + 1
            // console.log('change index')
          }
          if (!!row.flgdynamic && maxSortindex < row.sortindex) {
            maxSortindex = row.sortindex + 1
            maxIndex = index + 1
            // console.log('change index')
          }
        })
        console.log('maxSortIndex', maxSortindex)
        console.log('maxIndex', maxIndex)
        this.table.col.forEach((col) => {
          newRow[col.id] = {
            status: 'new',
            id: null,
            objectid: null,
            plcoldataid: col.id,
            pldcolid: col.plcolid,
            plrowdataid: null,
            sortindex: maxSortindex,
            value_dbl: null,
            value_int: null,
            value_str: '',
            main_value: '',
            colpllistid: col.pllistdataid,
            can_edit: col.pllistdataid ? 1 : 0,
            can_del: 1,
          }
          if (col.numcol === 1) {
            newRow[col.id].main_value = maxSortindex
          }
          newRow[col.id].styles = this.table.info.rowstylebydef + ''
          if (col.colstyles) {
            newRow[col.id].styles = newRow[col.id].styles + ',' + col.colstyles
          }
        })
        const newRowItem = {
          id: null,
          title: null,
          sortindex: maxSortindex,
          can_del: 1,
          height: '40px',
          flgvisible: 1,
          flgnumerated: 1,
          flgdynamic: 1,
        }
        // eslint-disable-next-line vue/no-mutating-props
        this.table.data.splice(maxIndex, 0, newRow)
        // eslint-disable-next-line vue/no-mutating-props
        this.table.row.splice(maxIndex, 0, newRowItem)
        this.$store.dispatch('setPlTablesChanges', { table: this.table.info.id, cell: 'addRow', changestate: true })
      },
      showVirtualObjectDialog (id = null) {
        if (id) {
          const vObj = this.objectsList.find(x => x.listitemdataid === id)
          this.virtualObject.title = vObj.title
          this.virtualObject.id = vObj.objectid
          this.virtualObject.listdataid = vObj.listitemdataid
        } else {
          this.virtualObject.title = this.$refs.selects.lazySearch
        }
        this.virtualObject.show = true
      },
      updateVirtualObject () {
        if (this.virtualObject.id) {
          const payload = {
            pllistitemid: this.virtualObject.listdataid,
            title: this.virtualObject.title,
          }
          this.$store.dispatch('updateVirtualObject', payload).then((resp) => {
            this.$store.dispatch('getObjectList', this.popupItem.colpllistid).then(() => {
              this.closeVirtualObjectDialog()
              this.popupItem.pllistitemdataid = resp[0].result
              this.setEditedAndObjectid(this.popupItem.pllistitemdataid)
            })
          })
        } else {
          this.$store.dispatch('addVirtualObject', { pllistid: this.popupItem.colpllistid, title: this.virtualObject.title }).then((resp) => {
            this.$store.dispatch('getObjectList', this.popupItem.colpllistid).then(() => {
              this.closeVirtualObjectDialog()
              this.popupItem.pllistitemdataid = resp[0].listitemdataid
              this.setEditedAndObjectid(this.popupItem.pllistitemdataid)
            })
          })
        }
      },
      updateListObject () {
        const pi = JSON.parse(JSON.stringify(this.popupItem))
        const colIndex = this.table.col.findIndex(c => c.id === pi.plcoldataid)
        const rowIndex = this.table.row.findIndex(r => r.id === pi.plrowdataid)
        this.chosenCell = {
          rowIndex: rowIndex,
          colIndex: colIndex,
          cellID: pi.id,
          colIndexEnd: null,
          rowIndexEnd: null,
        }
        this.objectsDialog = false
        this.popupItem = null
      },
      inputOut (value) {
        const valmask = value.datamask ? value.datamask.substr(1, 1) : ''
        if (value.main_datatype === 'Float' && valmask === 'p' && value.main_value) {
          value.main_value = value.main_value / 100
        }
        value.masked_value = this.mainValueFormatted(value)
        this.checkUpdate(value)
        this.selectedCell = null
      },
      saveAndRecalc (table) {
        this.$store.dispatch('setDataLoading', true)
        const tableDataUpdate = {
          versionid: table.info.plversionid,
          tabledataid: table.info.id,
          flgdynamic: table.info.flgdynamicrows,
          rows: [],
        }
        if (this.rowsToDelete[table.info.id]) {
          this.rowsToDelete[table.info.id].forEach((rowsDelete) => {
            tableDataUpdate.rows.push(rowsDelete)
          })
          this.rowsToDelete[table.info.id] = []
        }
        let first = this.table.col.find(x => x.numcol === 1)
        let haveNumCol = false
        if (first) {
          first = first.id
          haveNumCol = true
        }
        table.data.forEach((row) => {
          if (!first) {
            [first] = Object.keys(row)
          }
          if (row[first].status && row[first].status === 'new') {
            const newRow = {
              plrowdataid: null,
              sortindex: row[first].sortindex,
              status: 'new',
              data: [],
            }
            for (const key in row) {
              if (row[key].main_value !== '' && (haveNumCol ? +key !== +first : true)) {
                const newCell = {
                  pldataid: null,
                  plcoldataid: row[key].plcoldataid,
                  objectid: row[key].pllistitemdataid,
                  pllistitemdataid: row[key].pllistitemdataid,
                  value: row[key].main_value,
                }
                if (row[key].maincol === 1) {
                  newCell.maincol = 1
                }
                newRow.data.push(newCell)
              }
            }
            tableDataUpdate.rows.push(newRow)
          } else if (row[first].status && row[first].status === 'reorder') {
            const rowInfoReorder = this.table.row.find((r) => r.id === row[first].plrowdataid)
            const newRow = {
              plrowdataid: row[first].plrowdataid,
              sortindex: row[first].newsortindex,
              status: 'upd',
              flgdynamic: rowInfoReorder.flgdynamic,
              data: [],
            }
            for (const key in row) {
              if (first !== key && row[key].status && row[key].status === 'upd') {
                const newCell = {
                  pldataid: null,
                  plcoldataid: row[key].plcoldataid,
                  objectid: row[key].pllistitemdataid,
                  pllistitemdataid: row[key].pllistitemdataid,
                  value: row[key].main_value,
                }
                newRow.data.push(newCell)
              }
            }
            tableDataUpdate.rows.push(newRow)
          } else {
            for (const key in row) {
              if (row[key].status && row[key].status === 'upd' && (haveNumCol ? +key !== +first : true)) {
                const rowid = tableDataUpdate.rows.findIndex((val) => val.plrowdataid === row[key].plrowdataid)
                const rowInfo = this.table.row.find((r) => r.id === row[key].plrowdataid)
                console.log('rowid', rowid)
                console.log('rowid', row[key])
                console.log('rowid', rowInfo)
                if (rowid < 0) {
                  const updateRow = {
                    plrowdataid: row[key].plrowdataid,
                    sortindex: row[key].sortindex,
                    status: 'upd',
                    flgdynamic: rowInfo.flgdynamic,
                    data: [],
                  }
                  updateRow.data.push({
                    pldataid: row[key].id,
                    plcoldataid: row[key].plcoldataid,
                    objectid: row[key].pllistitemdataid,
                    pllistitemdataid: row[key].pllistitemdataid,
                    value: row[key].main_value,
                  })
                  tableDataUpdate.rows.push(updateRow)
                } else {
                  tableDataUpdate.rows[rowid].data.push({
                    pldataid: row[key].id,
                    plcoldataid: row[key].plcoldataid,
                    objectid: row[key].pllistitemdataid,
                    pllistitemdataid: row[key].pllistitemdataid,
                    flgdynamic: rowInfo.flgdynamic,
                    value: row[key].main_value,
                  })
                }
              }
            }
          }
        })
        this.$emit('updateTableData', tableDataUpdate)
        this.arrChangesTable = []
        this.lastModifiedId = []
        this.curStepChanges = 0
      },
      justSave (table) {
        this.$store.dispatch('setDataLoading', true)
        const tableDataUpdate = {
          versionid: table.info.plversionid,
          tabledataid: table.info.id,
          flgdynamic: table.info.flgdynamicrows,
          rows: [],
        }
        if (this.rowsToDelete[table.info.id]) {
          this.rowsToDelete[table.info.id].forEach((rowsDelete) => {
            tableDataUpdate.rows.push(rowsDelete)
          })
          this.rowsToDelete[table.info.id] = []
        }
        let first = this.table.col.find(x => x.numcol === 1)
        let haveNumCol = false
        if (first) {
          first = first.id
          haveNumCol = true
        }
        table.data.forEach((row) => {
          if (!first) {
            [first] = Object.keys(row)
          }
          if (row[first].status && row[first].status === 'new') {
            const newRow = {
              plrowdataid: null,
              sortindex: row[first].sortindex,
              status: 'new',
              data: [],
            }
            for (const key in row) {
              if (row[key].main_value !== '' && (haveNumCol ? +key !== +first : true)) {
                const newCell = {
                  pldataid: null,
                  plcoldataid: row[key].plcoldataid,
                  objectid: row[key].pllistitemdataid,
                  pllistitemdataid: row[key].pllistitemdataid,
                  value: row[key].main_value,
                }
                if (row[key].maincol === 1) {
                  newCell.maincol = 1
                }
                newRow.data.push(newCell)
              }
            }
            tableDataUpdate.rows.push(newRow)
          } else if (row[first].status && row[first].status === 'reorder') {
            const rowInfoReorder = this.table.row.find((r) => r.id === row[first].plrowdataid)
            const newRow = {
              plrowdataid: row[first].plrowdataid,
              sortindex: row[first].newsortindex,
              status: 'upd',
              flgdynamic: rowInfoReorder.flgdynamic,
              data: [],
            }
            for (const key in row) {
              if (first !== key && row[key].status && row[key].status === 'upd') {
                const newCell = {
                  pldataid: null,
                  plcoldataid: row[key].plcoldataid,
                  objectid: row[key].pllistitemdataid,
                  pllistitemdataid: row[key].pllistitemdataid,
                  value: row[key].main_value,
                }
                newRow.data.push(newCell)
              }
            }
            tableDataUpdate.rows.push(newRow)
          } else {
            for (const key in row) {
              if (row[key].status && row[key].status === 'upd' && (haveNumCol ? +key !== +first : true)) {
                const rowid = tableDataUpdate.rows.findIndex((val) => val.plrowdataid === row[key].plrowdataid)
                const rowInfo = this.table.row.find((r) => r.id === row[key].plrowdataid)
                console.log('rowid', rowid)
                console.log('rowid', row[key])
                console.log('rowid', rowInfo)
                if (rowid < 0) {
                  const updateRow = {
                    plrowdataid: row[key].plrowdataid,
                    sortindex: row[key].sortindex,
                    status: 'upd',
                    flgdynamic: rowInfo.flgdynamic,
                    data: [],
                  }
                  updateRow.data.push({
                    pldataid: row[key].id,
                    plcoldataid: row[key].plcoldataid,
                    objectid: row[key].pllistitemdataid,
                    pllistitemdataid: row[key].pllistitemdataid,
                    value: row[key].main_value,
                  })
                  tableDataUpdate.rows.push(updateRow)
                } else {
                  tableDataUpdate.rows[rowid].data.push({
                    pldataid: row[key].id,
                    plcoldataid: row[key].plcoldataid,
                    objectid: row[key].pllistitemdataid,
                    pllistitemdataid: row[key].pllistitemdataid,
                    flgdynamic: rowInfo.flgdynamic,
                    value: row[key].main_value,
                  })
                }
              }
            }
          }
        })
        this.$emit('updateTableDataLite', tableDataUpdate)
      },
      recalcOuterData (table) {
        this.$store.dispatch('setDataLoading', true)
        console.log('table', table)
        this.$store.dispatch('recalcOuterData', table.info.id).then((response) => {
          this.$store.commit('clearObjectList')
          this.$emit('updateTablesData', response)
        }).catch((e) => {
          console.log('Error', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      getRowHeight (pldrowindex) {
        const row = this.table.row[pldrowindex]
        if (row && row.height) {
          return row.height
        } else {
          return this.table.info.rowdefaultheight
        }
      },
      inputCheckInitValue (newValue, value) {
        if (value.pllistitemdataid) {
          console.log('check list item data')
        }
        if (value.main_datatype === 'Integer' || value.main_datatype === 'Float') {
          if (newValue && (!isNaN(newValue) || newValue === '-')) {
            value.main_value = newValue.replaceAll(' ', '')
          }
        } else if (value.main_datatype === 'String') {
          value.main_value = newValue
        }
        if (value && (newValue === '' || newValue === ' ' || newValue === null)) value.main_value = null
      },
      inputCheck (event, value) {
        const val = event
        if (val.target.value && val.target.value.length > 1 && val.target.value[0] == 0 && val.target.value[1] !== '.') { val.target.value = val.target.value.slice(1) }
        if (value.main_datatype === 'Integer') {
          const test = val.target.value.replace(/\s/g, '').match(/^-?\d+$/g)
          if (test || val.target.value.replace(/\s/g, '') === '-') {
            value.main_value = val.target.value.replace(/\s/g, '').replace(/^0+(?!$)/g, '')
          }
        } else if (value.main_datatype === 'Float') {
          // const roundingVol = this.value.datamask ? this.value.datamask.substr(4) || this.value.datamask.substr(2) : null
          const roundingExp = '*'
          const regExpString = `^[+-]?(([0-9]+([.,][0-9]${roundingExp})?)|([.,][0-9]${roundingExp}))$` // '^-?(?=.*[1-9])[0-9]*[.,]?[0-9]' + roundingExp + '$'
          const regexp = new RegExp(regExpString)
          const test = val.target.value.replace(/\s/g, '').match(regexp)
          if (test || val.target.value.replace(/\s/g, '') === '-') {
            value.main_value = val.target.value.replace(/\s/g, '').replace(/,/g, '.')
            // value.value_dbl = value.main_value
          }
        } else if (value.main_datatype === 'String') {
          value.main_value = val.target.value
        }
        if (value && (val.target.value === '' || val.target.value === ' ')) value.main_value = null
        this.$forceUpdate()
        val.target.value = value.main_value
      },
      mainValueFormatted (value) {
        if (value.main_value === '-') {
          return value.main_value
        }
        if (value.datamask && value.main_datatype !== 'String') {
          const valmask = value.datamask
          const maskType = valmask.substr(1, 1)
          const nullChange = valmask.substr(2, 2)
          if (!value.main_value) {
            if (value.main_value === null) {
              if (nullChange === 'nn' || nullChange === 'nd') return '-'
              if (nullChange === 'nz' && maskType === 'p') return '0%'
              if (nullChange === 'nz') return '0'
            }
            if (value.main_value === 0) {
              if ((nullChange === 'ze' || nullChange === 'zd') && maskType === 'p') return ''
              if (nullChange === 'ze' || nullChange === 'zd') return ''
            }
            return value.main_value
          }
          if (maskType === 'i') {
            return this.$func.numberWithSpaces(parseInt(Math.round(value.main_value)))
          }
          if (maskType === 'f') {
            const roundingVol = valmask.substr(4) || valmask.substr(2)
            if (!roundingVol) {
              return this.$func.numberWithSpaces(parseFloat(value.main_value))
            }
            let newVal = this.$func.numberWithSpaces(parseFloat(value.main_value).toFixed(parseInt(roundingVol, 10)))
            if (newVal == 0) {
              newVal = newVal.replace('-', '')
            }
            return newVal
          }
          if (maskType === 'p') {
            const roundingVol = valmask.substr(4) || valmask.substr(2)
            if (this.edit) {
              if (this.lastdot) {
                return (parseFloat(value.main_value) * 100).toFixed(parseInt(roundingVol, 10)) + '.'
              } else {
                return (parseFloat(value.main_value) * 100).toFixed(parseInt(roundingVol, 10))
              }
            } else {
              return this.$func.numberWithSpaces((parseFloat(value.main_value) * 100).toFixed(parseInt(roundingVol, 10))) + '%'
            }
          }
        }
        return value.main_value
      },
      numberWithSpaces (x) {
        if (x) {
          const parts = x.toString().split('.')
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          return parts.join('.')
        } else {
          return ''
        }
      },
      // addStyles (item) {
      //   let styleString = ''
      //   let innerStyle = ''
      //   const styleObj = {}
      //   const styles = this.presentation ? item.styles_presentation : item.styles
      //   let additionClass = null
      //   if (styles) {
      //     const styleArr = styles.split(',')
      //     styleArr.forEach((style) => {
      //       for (const key in this.tableStyles[style]) {
      //         if (key == 'pl-vertical-align') {
      //           if (!additionClass) additionClass = {}
      //           if (this.tableStyles[style][key] === 'middle') {
      //             additionClass = 'v-middle'
      //             styleObj.position = 'relative'
      //             styleObj['vertical-align'] = 'middle'
      //           } else if (this.tableStyles[style][key] === 'bottom') {
      //             additionClass = 'v-bottom'
      //             styleObj.position = 'relative'
      //           }
      //         } else if (key == 'padding') {
      //           innerStyle = 'padding:' + this.tableStyles[style][key] + '!important'
      //         } else {
      //           styleObj[key] = this.tableStyles[style][key]
      //         }
      //       }
      //     })
      //     styleString = JSON.stringify(styleObj)
      //       .replace(/,/g, ';')
      //       .replace(/"/g, '')
      //       .replace('{', '')
      //       .replace('}', '')
      //   }
      //   return { styleString, innerStyle, additionClass }
      // },
      compileHeight (rowspan, height) {
        let hString = ''
        if (rowspan) {
          hString = (height * 2) + 1
        } else {
          hString = height
        }
        return 'height: ' + hString + 'px;'
      },
      showSnapHistory (pltableid) {
        this.$emit('showSnapHistory', pltableid)
      },
    },
  }
</script>
